import InputModern from 'components/ui/Input/InputModern';
import React, { useContext, useState, useRef, useEffect } from 'react';
import CompanyOnboardingModalFooter from './CompanyOnboardingModalFooter';
import { Trans } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import autosize from 'autosize';

export default function StepCompanyWebsite() {
  const { company } = useContext(CompanyContext);
  const [name, setName] = useState(company?.name || '');
  const [description, setDescription] = useState(company?.description || '');
  const descriptionInputRef = useRef(null);

  useEffect(() => {
    if (descriptionInputRef.current) {
      autosize(descriptionInputRef.current);
    }
  }, [descriptionInputRef.current]);

  useEffect(() => {
    if (description) {
      autosize(descriptionInputRef.current);
    }
  }, [description]);

  return (
    <>
      <div className="flex flex-col gap-2 overflow-y-auto">
        <div className='lg:hidden text-center text-5xl mb-4'>💼</div>
        <h3 className='text-gray-900 mega-title text-center lg:text-left pb-0 text-2xl lg:!text-2xl'>
          Tell us about your company <span className='hidden lg:inline'>&nbsp;💼</span>
        </h3>
        <h6 className='text-gray-600 font-normal text-center lg:text-left text-base lg:text-sm pb-4 lg:text-base'>
          Enter your website and company description so Followr's AI can generate more accurate content.
        </h6>

        <InputModern 
          name="name"
          placeholder="Your company name"
          className="!w-full !px-4 !py-3 text-sm flex-1"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />

        <div className="flex flex-col gap-2">
          <div className='relative'>
            <InputModern 
              name="description"
              placeholder="Describe your company..."
              className="!w-full !px-4 !py-3 text-sm min-h-[160px]"
              textArea
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </div>
          <div className="flex justify-between items-center px-2">
            <p className='text-gray-600 dark:text-gray-400 font-normal text-xs lg:text-xs'>
              <span className='font-bold text-xs'>{description?.trim()?.length}</span> / 3,000 <Trans i18nKey={`companyDescription.chars`}>chars</Trans>. The more you describe your company, the better Followr's AI can generate content based on your company.
            </p>
          </div>
        </div>
      </div>

      <CompanyOnboardingModalFooter 
        props={{
          name,
          description
        }}
      />
    </>
  );
}
