import classNames from "classnames";
import { FormContainer, FormItem, Input, Segment } from "components/ui";
import { Field } from "formik";
import React, { useEffect, useState } from "react";
import { HiCheckCircle } from "react-icons/hi";


export const audienceAgeOptions = [
    { value: '11-25', desc: '', emoji: '👦' },
    { value: '25-40', desc: '', emoji: '🧑' },
    { value: '40-65', desc: '', emoji: '👨' },
    { value: '+65', desc: '', emoji: '👴' },
];

export const audienceGenderOptions = [
    { value: 'Male', desc: '', emoji: '🧑' },
    { value: 'Female', desc: '', emoji: '👩 ' },
];

const StepCompanyAudienceAgeGender = ({ form, setForm, errors }) => {

    const handleOnClickAge = (value) => {
        setForm({...form, company_audience_age: value});
    }

    const handleOnClickGender = (value) => {
        setForm({...form, company_audience_gender: value});
    }

    useEffect(() => {
        setForm({...form, company_audience_age: audienceAgeOptions.map(item => item.value), company_audience_gender: audienceGenderOptions.map(item => item.value)})
    }, []);

    return (
        <div className="">
            <div className="grid grid-cols-1 gap-0 lg:gap-6">
                <div>
                    <div className="mb-2 text-center text-base lg:text-lg font-bold text-gray-800 dark:text-gray-200 mega-title">Audience age</div>
                    {/* <div className="text-center mb-2 !text-xs lg:!text-sm">Select one or more options</div> */}
                    <Segment 
                        defaultValue={form?.company_audience_age ? [...form?.company_audience_age] : audienceAgeOptions.map(item => item.value)} 
                        className="gap-2 !block flex-col justify-center text-center"
                        selectionType="multiple"
                        onChange={(val) => handleOnClickAge(val)}
                    >
                        {audienceAgeOptions.map((item, index) => (
                            <Segment.Item
                                value={item.value}
                                key={item.value}
                            >
                                {({ ref, active, value, onSegmentItemClick }) => {
                                    return (
                                        <div
                                            ref={ref}
                                            className={classNames(
                                                'inline-flex',
                                                'whitespace-nowrap',
                                                'ring-1',
                                                'justify-between',
                                                'items-center m-1',
                                                'border',
                                                'rounded-xl',
                                                'border-gray-300 dark:border-gray-600',
                                                'py-2 px-2 lg:py-3 lg:px-3',
                                                'cursor-pointer',
                                                'select-none',
                                                'w-fit',
                                                'bg-white dark:bg-gray-800',
                                                active
                                                    ? 'ring-cyan-500 border-cyan-500 bg-primary-50/50 dark:!bg-primary-600/20'
                                                    : 'ring-transparent'
                                            )}
                                            onClick={() => {
                                                onSegmentItemClick();
                                            }}
                                        >
                                            <div className="gap-2.5 flex items-center w-full">
                                                <span className="!text-3xl">{item.emoji}</span>
                                                <p className="!text-sm leading-0 !m-0 !text-gray-800 dark:!text-gray-300">{item.value}</p>
                                                {active && <HiCheckCircle className="text-cyan-500 ml-1" />}
                                            </div>
                                        </div>
                                    )
                                }}
                            </Segment.Item>
                        ))}
                    </Segment>
                    {errors?.company_audience_age &&
                        <p className="text-center text-red-600">Please select an option</p>
                    }


                    <div className="mt-5 lg:mt-8 mb-2 text-center text-base lg:text-lg font-bold text-gray-800 dark:text-gray-200 mega-title">Audience gender</div>
                    {/* <div className="text-center mb-2 !text-xs lg:!text-sm">Select one or more options</div> */}
                    <Segment 
                        defaultValue={form?.company_audience_gender ? [...form?.company_audience_gender] : audienceGenderOptions.map(item => item.value)} 
                        className="gap-2 !block flex-col justify-center text-center"
                        selectionType="multiple"
                        onChange={(val) => handleOnClickGender(val)}
                    >
                        {audienceGenderOptions.map((item, index) => (
                            <Segment.Item
                                value={item.value}
                                key={item.value}
                            >
                                {({ ref, active, value, onSegmentItemClick }) => {
                                    return (
                                        <div
                                            ref={ref}
                                            className={classNames(
                                                'inline-flex',
                                                'whitespace-nowrap',
                                                'ring-1',
                                                'justify-between',
                                                'items-center m-1',
                                                'border',
                                                'rounded-xl',
                                                'border-gray-300 dark:border-gray-600',
                                                'py-2 px-2 lg:py-3 lg:px-3',
                                                'cursor-pointer',
                                                'select-none',
                                                'w-fit',
                                                'bg-white dark:bg-gray-800',
                                                active
                                                    ? 'ring-cyan-500 border-cyan-500 bg-primary-50/50 dark:!bg-primary-600/20'
                                                    : 'ring-transparent'
                                            )}
                                            onClick={() => {
                                                onSegmentItemClick();
                                            }}
                                        >
                                            <div className="gap-2.5 flex items-center w-full">
                                                <span className="!text-3xl">{item.emoji}</span>
                                                <p className="!text-sm leading-0 !m-0 !text-gray-800 dark:!text-gray-300">{item.value}</p>
                                                {active && <HiCheckCircle className="text-cyan-500 ml-1" />}
                                            </div>
                                        </div>
                                    )
                                }}
                            </Segment.Item>
                        ))}
                    </Segment>
                    {errors?.company_audience_gender &&
                        <p className="text-center text-red-600">Please select an option</p>
                    }
                </div>
            </div>
            


        </div>
    )
}

export default StepCompanyAudienceAgeGender;