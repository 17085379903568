import InputModern from 'components/ui/Input/InputModern';
import { SessionContext } from 'contexts/SessionContext';
import React, { useContext, useEffect, useState } from 'react'
import CompanyOnboardingModalFooter from './CompanyOnboardingModalFooter';

export default function StepConnectSocialNetworks() {

  const { user } = useContext(SessionContext);
  const [ name, setName ] = useState();

  useEffect(() => {
    setName(user?.name);
  }, [user]);

  return (
    <>
      <div className="flex flex-col gap-2">
        <div className='lg:hidden text-center text-5xl mb-4'>👋</div>
        <h3 
          className='text-gray-900 mega-title text-center lg:text-left pb-0 text-2xl lg:!text-2xl'
        >
          Let's get to know you better <span className='hidden lg:inline'>&nbsp;👋</span>
        </h3>
        <h6 
          className='text-gray-700 font-normal text-center lg:text-left text-base lg:text-sm pb-4 lg:text-base'
        >
          Tell us your name and we'll get started
        </h6>
        <InputModern 
          name="name"
          placeholder="John Doe"
          className="!w-full !px-4 !py-3 text-sm text-center lg:text-left"
          autoFocus={true}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <p className='text-gray-600 font-normal text-center lg:text-left text-xs lg:text-xs px-2'>
          This will be used to identify you in Followr.
        </p>
      </div>
      <CompanyOnboardingModalFooter 
        props={{
          name: name
        }}
      />
    </>
  )
}
