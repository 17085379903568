import React, { useContext, useEffect, useRef, useState } from 'react'
import { Alert, Button, Dialog, Dropdown, Input, Notification, Progress, Switcher, Tooltip, Upload, toast } from 'components/ui'
import { ModalContext } from 'contexts/ModalContext';
import { CompanyContext } from 'contexts/CompanyContext';
import { SessionContext } from 'contexts/SessionContext';
import { TbMoodCry } from 'react-icons/tb';
import InputModern from 'components/ui/Input/InputModern';
import moment from 'moment';
import Api from 'services/Api';
import { AppSumoContext } from 'contexts/AppSumoContext';

const CancelSubscriptionModal = ({ onCancel }) => {

    const { isPlus } = useContext(AppSumoContext);
    const { user, updateUserData } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const companies = user?.companies;
    const [ showUnsuscribeForm, setShowUnsuscribeForm ] = useState(false);
    const [ feedBack, setFeedBack ] = useState("");
    const [ isCancellingSubscription, setIsCancellingSubscription ] = useState(false);

    const recurring = user?.subscription?.items?.[0]?.price?.recurring;
    const start = user?.subscription?.trial_ends_at ? new Date(user?.subscription?.trial_ends_at) : new Date(user?.subscription?.created_at);
    let end = start;
    if (recurring == "year") {
        end.setFullYear(new Date().getFullYear());
        if (end < new Date()) {
            end = end.getTime() + 1000 * 3600 * 24 * 365;
        }
    } else {
        end.setFullYear(new Date().getFullYear());
        end.setMonth(new Date().getMonth());
        if (end < new Date()) {
            end = end.getTime() + 1000 * 3600 * 24 * 365 / 12;
        }
    }

    const encode = (data) => {
        return Object.keys(data).map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),).join('&');
    };

    const cancelSubscription = () => {
        setIsCancellingSubscription(true);
        (isPlus() ? Api.delete(`/subscriptionTokens/plus`) : Api.delete(`/subscriptions`, {})).then((response) => {
            try {
                fetch('/', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                    body: encode({ 
                        'form-name': "unsuscribe", 
                        'email': user?.email,
                        'name': user?.name,
                        'feedback': feedBack,
                    }),
                });
            } catch (err) {}
            toast.push(<Notification title="You have successfully cancelled your subscription." />);
            updateUserData();
            onCancel();
            setFeedBack("");
        }).catch((err) => {
            toast.push(<Notification title={err.message} type="danger" />);
        }).finally(() => {
            setIsCancellingSubscription(false);
        });
    }

    return (
        <Dialog
            isOpen={true}
            closable={true}
            onClose={onCancel}
            onRequestClose={onCancel}
            overlayClassName="flex w-full h-full items-center justify-center"
            width={900}
        >
            {!showUnsuscribeForm 
                ? <div className='flex items-stretch justify-center flex-col lg:flex-row w-auto -m-6 !overflow-hidden'>
                        <div className='shrink-0 flex items-center justify-center bg-primary-600 p-20'>
                            <img src="/img/others/cry.png" className='w-[100%] !max-w-[225px] drop-shadow-xl' />
                        </div>
                        <div className='flex-1 shrink-0 flex flex-col justify-between gap-2'>
                            <div className='p-8 py-8 flex flex-col gap-6'>
                                <h3 className='text-gray-900 mega-title text-center lg:text-left'>We are sorry to see you go!</h3>
                                <h6 className='text-gray-700 font-normal text-center lg:text-left'>{!isPlus() && <>Your {recurring == "year" ? `annual` : `monthly`} subscription is valid until {moment(user?.balance?.words_renews_at).format("MMMM DD, YYYY")}. </>}If you would like to proceed with cancelling your subscription, please click on "Cancel Subscription" below.</h6>
                                {!isPlus() && 
                                    <Alert type="danger" title="Are you sure you want to cancel your subscription?" showIcon customIcon={<TbMoodCry />}>
                                        After {moment(user?.balance?.words_renews_at).format("MMMM DD, YYYY")} your posts won't be automatically published anymore and you won't have access to your uploaded media, created copies and saved posts. 
                                    </Alert>
                                }
                            </div>
                            <div className='p-4 px-8 border-t border-gray-200 dark:border-gray-700 flex justify-end gap-4'>
                                <Button variant="plain" onClick={onCancel}>Go Back</Button>
                                <Button onClick={() => setShowUnsuscribeForm(true)}>Cancel Subscription</Button>
                            </div>
                        </div>
                    </div>
                : <div className='flex items-stretch justify-center flex-col lg:flex-row w-auto -m-6 !overflow-hidden'>
                    <div className='shrink-0 flex items-center justify-center bg-primary-600 p-20'>
                        <img src="/img/others/cry-2.png" className='w-[100%] !max-w-[245px] drop-shadow-xl' />
                    </div>
                    <div className='flex-1 shrink-0 flex flex-col justify-between gap-2'>
                        <div className='p-8 py-8 flex flex-col gap-6'>
                            <h3 className='text-gray-900 mega-title text-center lg:text-left'>Before you leave...</h3>
                            <h6 className='text-gray-700 font-normal text-center lg:text-left'>So that we can continue to improve our service, would you take a moment to tell us why you are cancelling your account?</h6>
                            <InputModern value={feedBack} onChange={(e) => setFeedBack(e.target.value)} textArea placeholder="Tell us why you are cancelling your account..." />
                        </div>
                        <div className='p-4 px-8 border-t border-gray-200 dark:border-gray-700 flex justify-end gap-4'>
                            <Button variant="plain" onClick={() => setShowUnsuscribeForm(false)}>Go Back</Button>
                            <Tooltip title={"Please, help us improve our service by giving us some feedback"} disabled={!!feedBack}>
                                <Button disabled={!feedBack || isCancellingSubscription} loading={isCancellingSubscription} onClick={cancelSubscription}>OK, cancel my subscription</Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
            }
        </Dialog>
    );
}

export default CancelSubscriptionModal