import { Button } from 'components/ui'
import { COMPANY_ONBOARDING_REQUIRED } from 'components/ui/utils/constant';
import { CompanyOnboardingContext } from 'contexts/CompanyOnboardingContext';
import { SessionContext } from 'contexts/SessionContext';
import React, { useContext } from 'react'
import { Trans } from 'react-i18next';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { TbPower } from 'react-icons/tb';

export default function CompanyOnboardingModalFooter({ 
  props, 
  onNextStep = undefined,
  onPrevStep = undefined,
  loading = false
}) {

  const {
    close,
    prevStep,
    nextStep,
    currentStep,
    steps
  } = useContext(CompanyOnboardingContext);
  const { user, actions } = useContext(SessionContext);
  
  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;
  const currentStepObj = steps[currentStep];

  return (
    <div className="flex justify-between items-center mt-8 pt-4 border-t border-gray-200 dark:border-gray-700 lg:!border-none">
      <div className='flex gap-2 items-center'>
        <div className='hidden text-sm lg:!flex mt-0.5 text-red-600 font-semibold items-center gap-1 justify-center cursor-pointer' onClick={() => {
          actions.logout();
        }}>
          <span><Trans i18nKey={`userDropdown.logOut`}>Log out</Trans></span>
        </div>
        {(!COMPANY_ONBOARDING_REQUIRED || user?.companies?.length > 1 || !!user?.subscription) && (
          <Button
            variant="plain" 
            onClick={close}
            size="sm"
            className="text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200"
          >
            <Trans i18nKey="common.cancel">Finish later</Trans>
          </Button>
        )}
      </div>
      <div className="flex gap-2">
        {!isFirstStep && (
          <Button
            variant="plain"
            onClick={onPrevStep ? onPrevStep : prevStep}
            icon={<HiChevronLeft />}
            size="sm"
          >
            <Trans i18nKey="common.previous">Previous</Trans>
          </Button>
        )}
        {currentStepObj?.isOptional && (
          <Button
            variant="plain"
            onClick={() => onNextStep ? onNextStep() : nextStep(props)}
            size="sm"
            className="!text-primary-600 !hidden lg:!flex"
          >
            <Trans i18nKey="common.skip">Skip</Trans>
          </Button>
        )}
        <Button
          variant="solid"
          onClick={() => onNextStep ? onNextStep() : nextStep(props)}
          icon={<HiChevronRight className="ml-1" />}
          size="sm"
          loading={loading}
        >
          {isLastStep ? (
            <Trans i18nKey="common.finish">Finish</Trans>
          ) : (
            <Trans i18nKey="common.continue">Continue</Trans>
          )}
        </Button>
      </div>
    </div>
  )
}
