import React, { useState, useEffect } from 'react';
import { Loading } from 'components/shared';
import { Trans } from 'react-i18next';
import { Spinner } from 'components/ui';

export const websiteScrapLoadingMessages = [
  "Analyzing your brand identity...",
  "Extracting website content...",
  "Crafting your brand voice...",
  "Generating color palette...",
  "Processing company information...",
  "Optimizing brand description...",
  "Setting up your AI environment...",
  "Personalizing your experience..."
];

const DynamicLoading = ({ 
  className = '',
  size = 40,
  messages = websiteScrapLoadingMessages,
  interval = 2000 
}) => {
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentMessage((prev) => (prev + 1) % messages.length);
    }, interval);

    return () => clearInterval(timer);
  }, [messages, interval]);

  return (
    <div className={`flex flex-col items-center justify-center gap-4 ${className}`}>
      <Spinner 
        type="element" 
        size={size} 
        color="primary-600"
        enableTheme={true}
      />
      <h2 className="text-gray-700 dark:text-gray-200 text-sm font-semibold animate-fade-in">
        <Trans>{messages[currentMessage]}</Trans>
      </h2>
    </div>
  );
};

export default DynamicLoading; 