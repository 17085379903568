import InputModern from 'components/ui/Input/InputModern';
import React, { useContext, useState, useRef, useEffect } from 'react';
import CompanyOnboardingModalFooter from './CompanyOnboardingModalFooter';
import { Trans } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import autosize from 'autosize';
import { Avatar, Notification, toast, Upload, Button, Tooltip } from 'components/ui';
import { Loading } from 'components/shared';
import { TbPlus, TbWand } from 'react-icons/tb';
import Resizer from "react-image-file-resizer";
import { SketchPicker } from 'react-color';
import { HiX } from 'react-icons/hi';
import { colorIsLight } from 'utils/colorUtils';
import { extractColors as extractImageColors } from 'extract-colors';
import { extractDominantColors } from 'utils/colorUtils';

export default function StepCompanyBrandStyle() {
  const { company } = useContext(CompanyContext);
  const [isLoadingLogo, setIsLoadingLogo] = useState(false);
  const [logo, setLogo] = useState(company?.image?.url || '');
  const [openedPicker, setOpenedPicker] = useState(-1);
  const [palettes, setPalettes] = useState(company?.palettes || ['', '', '']);
  const [isLoadingExtractColors, setIsLoadingExtractColors] = useState(false);

  const pickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        setOpenedPicker(-1);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const resizeFile = (file) => {
    return new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        256,
        256,
        file.type.includes("png") ? "PNG" : "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });
  };

  const onLogoUpload = (file) => {
    setIsLoadingLogo(true);
    resizeFile(file[0])
      .then((data) => {
        setLogo(data);
      })
      .finally(() => {
        setIsLoadingLogo(false);
      });
  };

  const onBeforeLogoUpload = (files) => {
    let valid = true;
    const allowedFileType = ['image/jpeg', 'image/png'];
    for (let file of files) {
      if (!allowedFileType.includes(file.type)) {
        valid = false;
        toast.push(
          <Notification type="error" title="Please upload a .jpeg or .png file" />
        );
      }
    }
    return valid;
  };

  const tooltips = {
    logo: "Upload your company logo",
    primary: "Select your primary brand color",
    secondary: "Select your secondary brand color",
    tertiary: "Select your accent/tertiary color"
  };

  const extractColors = () => {
    if (!logo) return;
    setIsLoadingExtractColors(true);
    extractDominantColors(logo)
      .then((colors) => {
        setPalettes(colors);
      })
      .finally(() => {
        setIsLoadingExtractColors(false);
      });
  };

  return (
    <>
      <div className="flex flex-col gap-2 overflow-y-auto">
        <div className='lg:hidden text-center text-5xl mb-4'>🎨</div>
        <h3 className='text-gray-900 mega-title text-center lg:text-left pb-0 text-2xl lg:!text-2xl'>
          Brand Style <span className='hidden lg:inline'>&nbsp;🎨</span>
        </h3>
        <h6 className='text-gray-600 font-normal text-center lg:text-left text-sm pb-4 lg:text-base'>
          Set your brand style to help Followr's AI generate content that matches your company's look &amp; feel.
        </h6>

        <div className='w-full flex flex-wrap gap-2'>
          <Tooltip title={tooltips.logo} wrapperClass='w-full lg:!w-auto'>
            <div className="relative border border-gray-200 dark:border-gray-700 rounded-2xl p-2 group hover:border-primary-600 hover:bg-primary-50/30 dark:hover:bg-primary-900/10 transition-all cursor-pointer w-full lg:!w-auto">
              <Upload
                className="cursor-pointer shrink-0 w-full lg:!w-auto"
                onChange={onLogoUpload}
                showList={false}
                uploadLimit={1}
                beforeUpload={onBeforeLogoUpload}
                accept="image/jpeg, image/png"
              >
                <div className="flex justify-center">
                  <Avatar
                    size={110}
                    avatarClassName={`!object-contain group-hover:scale-[1.02] transition-transform`}
                    className={`${logo ? `bg-transparent dark:bg-transparent` : `!bg-primary-600/30 !text-white rounded-full group-hover:!bg-primary-600/40`}`}
                    src={logo}
                    icon={isLoadingLogo ? <Loading type="element" /> : <TbPlus className="group-hover:scale-110 transition-transform" />}
                  />
                </div>
              </Upload>
              {logo && (
                <div className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity">
                  <Button
                    shape="circle"
                    variant="plain"
                    size="xs"
                    onClick={(e) => {
                      e.stopPropagation();
                      setLogo('');
                    }}
                    icon={<HiX className="text-lg" />}
                  />
                </div>
              )}
            </div>
          </Tooltip>

          <div className='w-full lg:!w-auto grid grid-cols-3 lg:flex flex-wrap gap-2'>
            {[0, 1, 2].map((index) => (
              <>
                <Tooltip key={index} title={[tooltips.primary, tooltips.secondary, tooltips.tertiary][index]} wrapperClass='grid-item w-full lg:w-auto'>
                  <div className="relative w-full lg:w-auto border border-gray-200 dark:border-gray-700 rounded-2xl p-2 group hover:border-primary-600 hover:bg-primary-50/30 dark:hover:bg-primary-900/10 transition-all cursor-pointer min-w-0">
                    <div
                      onClick={(e) => {
                        setOpenedPicker(index);
                        e.stopPropagation();
                      }}
                      className={`w-full h-[100px] lg:w-[110px] lg:h-[110px] flex items-center justify-center rounded-xl ${palettes[index] ? 'font-bold text-base' : 'bg-gray-100 text-gray-400 dark:bg-gray-700'}`}
                      style={{
                        backgroundColor: palettes[index] || '',
                        color: !palettes[index] || colorIsLight(palettes[index]) ? '#666666' : '#fafafa'
                      }}
                    >
                      {palettes[index] || <TbPlus className="text-2xl group-hover:scale-110 transition-transform" />}
                    </div>
                    <div className="absolute top-1 right-1 opacity-0 group-hover:opacity-100 transition-opacity">
                      <Button
                        shape="circle"
                        variant="plain"
                        size="xs"
                        onClick={(e) => {
                          e.stopPropagation();
                          const newPalettes = [...palettes];
                          newPalettes[index] = '';
                          setPalettes(newPalettes);
                        }}
                        icon={<HiX className="text-lg" />}
                      />
                    </div>
                  </div>
                </Tooltip>
                {openedPicker === index && (
                  <div
                    ref={pickerRef}
                    onClick={(e) => e.stopPropagation()}
                    style={{ zIndex: 999999 }}
                    className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  >
                    <SketchPicker
                      color={palettes[index] || '#ffffff'}
                      onChange={({ hex: color }) => {
                        const newPalettes = [...palettes];
                        newPalettes[index] = color;
                        setPalettes(newPalettes);
                      }}
                    />
                  </div>
                )}
              </>
            ))}
          </div>
        </div>

        {logo && (
          <div className="flex justify-center mt-2">
            <Button
              variant="twoTone"
              onClick={extractColors}
              className="!text-xs lg:!px-6 flex items-center gap-1 lg:gap-3"
              size="sm"
              loading={isLoadingExtractColors}
            >
              <TbWand />
              <span><Trans i18nKey="companyBranding.getColorsFromLogo">Get colors from logo</Trans></span>
            </Button>
          </div>
        )}
      </div>

      <CompanyOnboardingModalFooter
        props={{
          image: logo ? { url: logo } : undefined,
          palettes
        }}
      />
    </>
  );
}
