import React, { useContext, useState, useEffect } from 'react';
import InputModern from 'components/ui/Input/InputModern';
import { Trans } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import CreatableSelect from 'react-select/creatable';
import { Select, Button, Notification, toast } from 'components/ui';
import { CustomControl, CustomSelectOption } from 'views/company_settings/components/CompanyProfile';
import BasicRow from 'components/BasicRow';

// You can reuse the language options array from your onboarding
export const languageOptions = [
  { iso_code: "en", value: "English (US)", image: "/img/flags/us.png", disabled: false },
  { iso_code: "en", value: "English (GB)", image: "/img/flags/gb.png", disabled: false },
  { iso_code: "es", value: "Spanish", image: "/img/flags/es.png", disabled: false },
  { iso_code: "es", value: "Spanish (LATAM)", image: "/img/flags/es.png", disabled: false },
  { iso_code: "es", value: "Spanish (MX)", image: "/img/flags/mx.png", disabled: false },
  { iso_code: "fr", value: "French", image: "/img/flags/fr.png", disabled: false },
  { iso_code: "de", value: "German", image: "/img/flags/de.png", disabled: false },
  { iso_code: "it", value: "Italian", image: "/img/flags/it.png", disabled: false },
  { iso_code: "pt", value: "Portuguese", image: "/img/flags/pt.png", disabled: false },
  { iso_code: "pt", value: "Portuguese (Brazil)", image: "/img/flags/br.png", disabled: false },
  { iso_code: "nl", value: "Dutch", image: "/img/flags/nl.png", disabled: false },
  { iso_code: "ru", value: "Russian", image: "/img/flags/ru.png", disabled: false },
  { iso_code: "ja", value: "Japanese", image: "/img/flags/jp.png", disabled: false },
  { iso_code: "ko", value: "Korean", image: "/img/flags/kr.png", disabled: false },
  { iso_code: "zh", value: "Chinese", image: "/img/flags/cn.png", disabled: false },
  { iso_code: "zh", value: "Chinese (Simp)", image: "/img/flags/cn.png", disabled: false },
  { iso_code: "ar", value: "Arabic", image: "/img/flags/ae.png", disabled: false },
  { iso_code: "hi", value: "Hindi", image: "/img/flags/in.png", disabled: false },
  { iso_code: "fil", value: "Filipino", image: "/img/flags/ph.png", disabled: false },
  { iso_code: "id", value: "Indonesian", image: "/img/flags/id.png", disabled: false },
  { iso_code: "ms", value: "Malay", image: "/img/flags/my.png", disabled: false },
  { iso_code: "vi", value: "Vietnamese", image: "/img/flags/vn.png", disabled: false },
  { iso_code: "th", value: "Thai", image: "/img/flags/th.png", disabled: false },
  { iso_code: "tr", value: "Turkish", image: "/img/flags/tr.png", disabled: false },
  { iso_code: "sv", value: "Swedish", image: "/img/flags/se.png", disabled: false },
  { iso_code: "no", value: "Norwegian", image: "/img/flags/no.png", disabled: false },
  { iso_code: "da", value: "Danish", image: "/img/flags/dk.png", disabled: false },
  { iso_code: "pl", value: "Polish", image: "/img/flags/pl.png", disabled: false },
  { iso_code: "cs", value: "Czech", image: "/img/flags/cz.png", disabled: false },
  { iso_code: "sk", value: "Slovak", image: "/img/flags/sk.png", disabled: false },
  { iso_code: "hu", value: "Hungarian", image: "/img/flags/hu.png", disabled: false },
  { iso_code: "ro", value: "Romanian", image: "/img/flags/ro.png", disabled: false },
  { iso_code: "bg", value: "Bulgarian", image: "/img/flags/bg.png", disabled: false },
  { iso_code: "sr", value: "Serbian", image: "/img/flags/rs.png", disabled: false },
  { iso_code: "hr", value: "Croatian", image: "/img/flags/hr.png", disabled: false },
  { iso_code: "el", value: "Greek", image: "/img/flags/gr.png", disabled: false },
  { iso_code: "cat", value: "Catalan", image: "/img/flags/cat.png", disabled: false },
  { iso_code: "he", value: "Hebrew", image: "/img/flags/il.png", disabled: false },
  { iso_code: "sl", value: "Slovenian", image: "/img/flags/sl.png", disabled: false },
  { iso_code: "ge", value: "Georgian", image: "/img/flags/ge.png", disabled: false },
  { iso_code: "mk", value: "Macedonian", image: "/img/flags/mk.png", disabled: false }
];

export default function BrandVoice() {
  const { company, updateCompany } = useContext(CompanyContext);
  const [interests, setInterests] = useState(company?.interests || []);
  const [audience_types, setAudienceTypes] = useState(company?.audience_types || []);
  const [tones, setTones] = useState(company?.tones || []);
  const [emotions, setEmotions] = useState(company?.emotions || []);
  const [syntaxes, setSyntaxes] = useState(company?.syntaxes || []);
  const [language_types, setLanguageTypes] = useState(Array.isArray(company?.language_types) ? company.language_types : []);
  const [language_iso_code, setLanguageIsoCode] = useState(company?.language_iso_code || '');
  const [language, setLanguage] = useState(company?.language || '');
  const [characters, setCharacters] = useState(company?.characters || []);
  // New state for audience ages and genders
  const [audience_ages, setAudienceAges] = useState(company?.audience_ages || []);
  const [audience_genders, setAudienceGenders] = useState(company?.audience_genders || []);

  // Loading state for Save button
  const [loading, setLoading] = useState(false);

  const [audienceTypesOptions, setAudienceTypesOptions] = useState(
    (Array.isArray(audience_types) ? audience_types : []).map(e => ({ value: e, label: e }))
  );
  const [interestsOptions, setInterestsOptions] = useState(
    (Array.isArray(interests) ? interests : []).map(e => ({ value: e, label: e }))
  );
  const [tonesOptions, setTonesOptions] = useState(
    (Array.isArray(tones) ? tones : []).map(e => ({ value: e, label: e }))
  );
  const [emotionsOptions, setEmotionsOptions] = useState(
    (Array.isArray(emotions) ? emotions : []).map(e => ({ value: e, label: e }))
  );
  const [syntaxesOptions, setSyntaxesOptions] = useState(
    (Array.isArray(syntaxes) ? syntaxes : []).map(e => ({ value: e, label: e }))
  );
  const [languageTypesOptions, setLanguageTypesOptions] = useState(
    (Array.isArray(language_types) ? language_types : []).map(e => ({ value: e, label: e }))
  );
  const [charactersOptions, setCharactersOptions] = useState(
    (Array.isArray(characters) ? characters : []).map(e => ({ value: e, label: e }))
  );
  // New options for audience ages and genders
  const [audienceAgesOptions, setAudienceAgesOptions] = useState(
    (Array.isArray(company?.audience_ages) ? company.audience_ages : []).map(e => ({ value: e, label: e }))
  );
  const [audienceGendersOptions, setAudienceGendersOptions] = useState(
    (Array.isArray(company?.audience_genders) ? company.audience_genders : []).map(e => ({ value: e, label: e }))
  );

  // Update state whenever company changes
  useEffect(() => {
    if (company) {
      setInterests(company.interests || []);
      setAudienceTypes(company.audience_types || []);
      setTones(company.tones || []);
      setEmotions(company.emotions || []);
      setSyntaxes(company.syntaxes || []);
      setLanguageTypes(Array.isArray(company.language_types) ? company.language_types : []);
      setLanguageIsoCode(company.language_iso_code || '');
      setLanguage(company.language || '');
      setCharacters(company.characters || []);
      setAudienceAges(company.audience_ages || []);
      setAudienceGenders(company.audience_genders || []);

      setInterestsOptions(
        (Array.isArray(company.interests) ? company.interests : []).map(e => ({ value: e, label: e }))
      );
      setAudienceTypesOptions(
        (Array.isArray(company.audience_types) ? company.audience_types : []).map(e => ({ value: e, label: e }))
      );
      setTonesOptions(
        (Array.isArray(company.tones) ? company.tones : []).map(e => ({ value: e, label: e }))
      );
      setEmotionsOptions(
        (Array.isArray(company.emotions) ? company.emotions : []).map(e => ({ value: e, label: e }))
      );
      setSyntaxesOptions(
        (Array.isArray(company.syntaxes) ? company.syntaxes : []).map(e => ({ value: e, label: e }))
      );
      setLanguageTypesOptions(
        (Array.isArray(company.language_types) ? company.language_types : []).map(e => ({ value: e, label: e }))
      );
      setCharactersOptions(
        (Array.isArray(company.characters) ? company.characters : []).map(e => ({ value: e, label: e }))
      );
      setAudienceAgesOptions(
        (Array.isArray(company.audience_ages) ? company.audience_ages : []).map(e => ({ value: e, label: e }))
      );
      setAudienceGendersOptions(
        (Array.isArray(company.audience_genders) ? company.audience_genders : []).map(e => ({ value: e, label: e }))
      );
    }
  }, [company]);

  // Save handler for updating company settings
  const handleSave = () => {
    setLoading(true);
    const payload = {
      language_iso_code: language_iso_code || 'en',
      language: language || 'English (US)',
      interests: interests?.length ? interests : [],
      audience_types: audience_types?.length ? audience_types : [],
      tones: tones?.length ? tones : [],
      emotions: emotions?.length ? emotions : [],
      syntaxes: syntaxes?.length ? syntaxes : [],
      language_types: language_types?.length ? language_types : [],
      characters: characters?.length ? characters : [],
      audience_ages: audience_ages?.length ? audience_ages : [],
      audience_genders: audience_genders?.length ? audience_genders : []
    };
    updateCompany(payload)
      .then(() =>
        toast.push(
          <Notification type="success" title="Company updated successfully." />
        )
      )
      .catch(() =>
        toast.push(
          <Notification type="danger" title="Update failed." />
        )
      )
      .finally(() => setLoading(false));
  };

  return (
    <>
      <div className="flex flex-col gap-2 overflow-y-auto">
        <div className="lg:hidden text-center text-5xl mb-4 scale-x-[-1]">📢</div>
        <h3 className="text-gray-900 dark:text-gray-100 mega-title text-center lg:text-left pb-0 text-2xl lg:!text-2xl">
          Brand Voice &nbsp;<span className="hidden lg:inline scale-x-[-1] inline-block">📢</span>
        </h3>
        <h6 className="text-gray-600 font-normal text-center lg:text-left text-sm pb-4 lg:text-base">
          Set your brand voice and tone to help Followr&apos;s AI generate content that matches your company&apos;s communication style.
        </h6>

        <div className="flex flex-col gap-4">
          <BasicRow 
            label="Language 🏳️" 
            description="Select your brand's primary language."
          >
            <Select
              size="md"
              options={languageOptions}
              components={{
                Option: CustomSelectOption,
                Control: CustomControl,
              }}
              value={
                languageOptions.find(option => option.value === language) ||
                languageOptions.find(option => option.iso_code === language_iso_code)
              }
              onChange={(option) => {
                setLanguage(option.value);
                setLanguageIsoCode(option.iso_code);
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Topics 📌" 
            description="Choose key topics that reflect your brand's interests."
          >
            <Select
              isMulti
              options={interestsOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              value={interests.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setInterestsOptions([...interestsOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setInterestsOptions(interestsOptions.filter(e => e.value !== action.removedValue.value));
                }
                setInterests(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Audiences 🎯" 
            description="Define your target audience segments."
          >
            <Select
              isMulti
              options={audienceTypesOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              value={audience_types.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setAudienceTypesOptions([...audienceTypesOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setAudienceTypesOptions(audienceTypesOptions.filter(e => e.value !== action.removedValue.value));
                }
                setAudienceTypes(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Audience Ages 🔢" 
            description="Select target audience age groups."
          >
            <Select
              isMulti
              options={audienceAgesOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              value={audience_ages.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setAudienceAgesOptions([...audienceAgesOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setAudienceAgesOptions(audienceAgesOptions.filter(e => e.value !== action.removedValue.value));
                }
                setAudienceAges(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Audience Genders 👥" 
            description="Select the genders of your target audience."
          >
            <Select
              isMulti
              options={audienceGendersOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              value={audience_genders.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setAudienceGendersOptions([...audienceGendersOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setAudienceGendersOptions(audienceGendersOptions.filter(e => e.value !== action.removedValue.value));
                }
                setAudienceGenders(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Tones 🎤" 
            description="Select tone guidelines to represent your brand's voice."
          >
            <Select
              isMulti
              options={tonesOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              placeholder="Write your tones and press enter to add."
              value={tones.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setTonesOptions([...tonesOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setTonesOptions(tonesOptions.filter(e => e.value !== action.removedValue.value));
                }
                setTones(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Language Types 🗣️" 
            description="Define your company's language types."
          >
            <Select
              isMulti
              options={languageTypesOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              placeholder="Write your company language types and press enter to add."
              value={language_types.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setLanguageTypesOptions([...languageTypesOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setLanguageTypesOptions(languageTypesOptions.filter(e => e.value !== action.removedValue.value));
                }
                setLanguageTypes(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Syntax 📝" 
            description="Define your brand's syntax styles."
          >
            <Select
              isMulti
              options={syntaxesOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              placeholder="Write your syntax styles and press enter to add."
              value={syntaxes.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setSyntaxesOptions([...syntaxesOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setSyntaxesOptions(syntaxesOptions.filter(e => e.value !== action.removedValue.value));
                }
                setSyntaxes(option.map(item => item.value));
              }}
            />
          </BasicRow>

          <BasicRow 
            label="Characters 🦸" 
            description="Define your brand's character traits."
          >
            <Select
              isMulti
              options={charactersOptions}
              componentAs={CreatableSelect}
              className="hide-indicators"
              placeholder="Write your character traits and press enter to add."
              value={characters.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action === 'set-value') {
                  setCharactersOptions([...charactersOptions, { value: action.prevInputValue, label: action.prevInputValue }]);
                }
              }}
              onChange={(option, action) => {
                if (action.action === 'remove-value') {
                  setCharactersOptions(charactersOptions.filter(e => e.value !== action.removedValue.value));
                }
                setCharacters(option.map(item => item.value));
              }}
            />
          </BasicRow>
        </div>
      </div>
      <div className="flex justify-end mt-4">
        <Button variant="twoTone" onClick={handleSave} loading={loading}>
          Save
        </Button>
      </div>
    </>
  );
} 