import Api from "services/Api";
import { Readability } from '@mozilla/readability';

export const formatUrl = (url) => (!/^https?:\/\//i.test(url)) ? 'https://' + url : url;

export const getReadableByURL = async (url) => {
    return Api.get(`/scraper/html?url=${url}`, {}).then((responseText) => {
        var doc = new DOMParser().parseFromString(responseText, "text/html");
        var article = new Readability(doc).parse();
        return article;
    });
} 

export const getHTMLByURL = async (url) => {
    return Api.get(`/scraper/html?url=${url}`, {}).then((html) => {
        var doc = new DOMParser().parseFromString(html, "text/html");
        var article = new Readability(doc).parse();
        return { article, html };
    });
} 