import React, { ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Api from "services/Api";
import { SessionContext } from "./SessionContext";
import { PriorityModalContext } from "./PriorityModalContext";
import OutOfCompaniesModal from "components/layout/OutOfCompaniesModal";
import { allNetworks } from "views/calendar/SocialNetworkSidePanel";
import axios from "axios";
import { proxyUrl } from "utils/proxy";

const CompanyContext = React.createContext({
    company: undefined,
    setCompany: (company) => void 0,
    companyUsers: [],
    companySocials: [],
    isLoadingCompanySocials: true,
    updateSocials: () => void 0,
    updateCompanyUsers: () => void 0,
    updateCompanyLogoUrl: (url) => void 0,
    mainUser: undefined,
    subscriptionPlanName: undefined
});

const CompanyProvider = ({ children }) => {

    const { user, session, updateBalance } = useContext(SessionContext);
    const [ isLoadingCompanySocials, setIsLoadingCompanySocials ] = useState(true);
    const [ mainUser, setMainUser ] = useState(user);
    const companies = user?.companies;

    const [ companyUsers, setCompanyUsers ] = useState([]);
    const [ company, setCompany ] = useState();
    const [ companySocials, setCompanySocials ] = useState(
        localStorage.getItem(`companySocials${company?.id}`)
        ? JSON.parse(localStorage.getItem(`companySocials${company?.id}`))
        : []
    );

	const subscriptionPlanName = useMemo(() => {
		return mainUser?.subscription?.items?.[0]?.product?.name || 'free';
	}, [mainUser?.subscription]);

    const updateSocials = () => {
        if (company && session?.isLoggedIn) {
            try {
                Api.get(`/companies/${company?.id}/socialNetworks?include=image.thumbnail`).then(({ data: socials }) => {
                    socials = socials.map((e) => {
                        return {
                            ...e,
                            company_id: company?.id
                        }
                    })
                    setCompanySocials(socials.filter(e => allNetworks.includes(e.type)));
                }).finally(() => {
                    setIsLoadingCompanySocials(false);
                });
            } catch(err) {
                setCompanySocials([]);
            }
        }
    }

    useEffect(() => {
        if (!window.location.href.includes("/onboarding")) {
            if (companies?.length && company) {
                setCompany(
                    companies?.filter(e => e.id == company.id)?.[0] 
                        ? companies?.filter(e => e.id == company.id)?.[0]
                        : companies?.length 
                            ? localStorage.getItem(`actualCompanyID${user?.id}`) 
                                ? companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
                                : companies[0]
                            : undefined
                );
            } else if (companies?.length && !company) {
                const _company = companies.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0];
                setCompany(_company || companies[0]);
            }
        }
    }, [user?.companies?.length, company?.id, user]);

    useEffect(() => {
        if (company?.id && user?.id) {
            localStorage.setItem(`actualCompanyID${user?.id}`, company?.id);
        } 
        if (!company?.id && user?.id && window.location.pathname == "/onboarding") {
            localStorage.removeItem(`actualCompanyID${user?.id}`);
        } 
    }, [company?.id, user?.id]);

    useEffect(() => {
        // if (company) {
        //     localStorage.setItem(`company${user?.id}`, JSON.stringify(company));
        // } else {
        //     if (user) {
        //         localStorage.removeItem(`company${user?.id}`, JSON.stringify(company));
        //     }
        // }
    }, [company?.id]);

    useEffect(() => {
        if (!window.location.href.includes("/onboarding")) {
            if (companies?.length && !company) {
                setCompany(
                    localStorage.getItem(`actualCompanyID${user?.id}`) && companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
                        ? companies?.filter(e => e.id == localStorage.getItem(`actualCompanyID${user?.id}`))?.[0]
                        : companies[0]
                );
            }
        }
    }, [companies]);

    useEffect(() => {
        if (company && companies) {
            setCompany(companies?.filter(e => e.id == company.id)?.[0]);
        }
    }, [companies]);

    const updateCompanyUsers = () => {
        if (company) {
            Api.get(`/users?filter[companies.id]=${company?.id}&include=image,roleUsers,roleUsers.role,subscriptionTokens,subscriptions.items.product`).then(({ data: _companyUsers }) => {
                _companyUsers = _companyUsers.map(u => {
                    u.subscription = u?.subscriptions?.find(e => e?.stripe_status == `active` || e?.stripe_status == `trialing`) || undefined;
                    return u;
                });
                setCompanyUsers(_companyUsers);
            });
        } else {
            setCompanyUsers([]);
        }
    }

    useEffect(() => {
        updateCompanyUsers();
    }, [company?.id]);

    useEffect(() => {
        if (companyUsers?.length && company?.id) {
            setMainUser(companyUsers?.find(e => e.id == company?.user?.id));
        }
    }, [companyUsers?.length, JSON.stringify(companyUsers), company?.id]);

    useEffect(() => {
        setIsLoadingCompanySocials(true);
        setCompanySocials([]);
        updateSocials();
        updateBalance();
    }, [company?.id]);

    useEffect(() => {
        if (companySocials && company) {
            localStorage.setItem(`companySocials${company?.id}`, JSON.stringify(companySocials));
        } else {
            localStorage.removeItem(`companySocials${company?.id}`);
        }
        if (company) {
            if (companySocials && companySocials?.length) {
                window.Intercom('update', {
                    "has_social_network": true
                });
            } else {
                window.Intercom('update', {
                    "has_social_network": false
                });
            }
        }
    }, [company?.id, companySocials]);

    useEffect(() => {
        var url = new URL(window.location.href);
        var company_id = url.searchParams.get("company_id");
        if (company_id && companies && companies?.length > 0) {
            url.searchParams.delete("company_id");
            const _company = companies.filter(e => e.id == company_id);
            if (_company && _company?.length > 0) {
                setCompany(_company[0]);
            }
        }
    }, [companies]);

    const updateCompanyLogoUrl = async (url) => {
        try {
            await fetch(url.startsWith('data:image') ? url : proxyUrl(url))
                .then(res => res.blob())
                .then(blob => {
                    const imageType = !blob?.type?.split('/')?.[1] 
                        ? url?.includes("data:image/") 
                            ? url?.substring("data:image/"?.length, url.indexOf(";base64"))
                            : url?.split('.')?.pop()?.toLowerCase()
                        : blob?.type?.split('/')?.[1];
                    const name = `logo.${imageType}`;
                    const file = new File([blob], name, { type: `image/${imageType}` });
                    const type = file.type.split("/")[0];
                    Api.post(`/companies/${company?.id}/image`, {}, { filename: name, type: type, visibility: "public" }).then((response) => {
                        const aws_uri = response.data.presigned_url;
                        axios.put(aws_uri, file, { headers: { 'Content-type': file.type } }).then(() => {
                            // setCompany(prev => ({ ...prev, image: { url: response.data.url } }));
                        });
                    });
                })
        } catch (err) {}
    }

    const updateCompany = async (payload) => {
        try {
            const response = await Api.put(`/companies/${company?.id}`, {}, payload);
            if (payload?.image?.url) {
                await updateCompanyLogoUrl(payload?.image?.url);
            }
            setCompany(prev => ({ ...prev, ...payload }));
            return response.data;
        } catch (error) {
            console.error('Error updating company:', error);
            throw error;
        }
    }

    return (
        <CompanyContext.Provider value={{ 
            company: company, 
            setCompany: setCompany, 
            roles: [company?.role],
            companyUsers: companyUsers,
            companySocials: companySocials,
            isLoadingCompanySocials: isLoadingCompanySocials,
            updateSocials: updateSocials,
            updateCompanyUsers: updateCompanyUsers,
            updateCompanyLogoUrl: updateCompanyLogoUrl,
            updateCompany: updateCompany,
            mainUser: mainUser,
            subscriptionPlanName: subscriptionPlanName
        }}>
            {children}
        </CompanyContext.Provider>
    );
};

export { CompanyContext, CompanyProvider };