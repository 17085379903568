import CompanyOnboardingModal from "components/company_onboarding/CompanyOnboardingModal";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { SessionContext } from "contexts/SessionContext";
import { CompanyContext } from "contexts/CompanyContext";
import { HiPencil, HiOutlineUserGroup } from "react-icons/hi2";
import { TbBuildingStore, TbLanguage, TbNetwork, TbPalette, TbSpeakerphone, TbTargetArrow, TbWorldWww } from "react-icons/tb";
import StepConnectSocialNetworks from "components/company_onboarding/StepConnectSocialNetworks";
import StepProfile from "components/company_onboarding/StepProfile";
import StepCompanyWebsite from "components/company_onboarding/StepCompanyWebsite";
import StepCompanyProfile from "components/company_onboarding/StepCompanyProfile";
import Api from "services/Api";
import StepCompanyBrandVoice from "components/company_onboarding/StepCompanyBrandVoice";
import { COMPANY_ONBOARDING_REQUIRED } from "components/ui/utils/constant";
import StepCompanyBrandStyle from "components/company_onboarding/StepCompanyBrandStyle";
import ConnectASocialNetworkModal from "components/layout/ConnectASocialNetworkModal";
import { Notification, toast } from "components/ui";

const CompanyOnboardingContext = React.createContext({
    isOpened: false,
    currentStep: 0,
    steps: [],
    nonOnboardingSteps: [],
    completionPercentage: 0,
    isCompleted: false,
    open: (stepId = null) => void 0,
    close: () => void 0,
    nextStep: (...props) => void 0,
    prevStep: () => void 0,
    goToStep: (stepId) => void 0
});

const CompanyOnboardingProvider = ({ children }) => {
    const [ isOpened, setIsOpened ] = useState(false); 
    const [ currentStep, setCurrentStep ] = useState(0);
    const [ isLoading, setIsLoading ] = useState(true);
    const [ completionPercentage, setCompletionPercentage ] = useState(0);
    const [ fullCompletionPercentage, setFullCompletionPercentage ] = useState(0);
    const { user, updateUserData } = useContext(SessionContext);
    const { company, companySocials, setCompany, updateCompanyLogoUrl, isLoadingCompanySocials } = useContext(CompanyContext);

    const steps = useMemo(() => [
        {
            id: 'profile',
            title: 'Complete Your Profile',
            description: 'Add your profile image and name',
            icon: <HiPencil />,
            isComplete: !!(user?.name),
            component: <StepProfile />,
            validate: (props) => {
                props = { ...user, ...props };
                if (!props?.name?.trim()) {
                    return 'Please enter your name';
                }
                return true;
            },
            action: async (props) => {
                await Api.put(`/users/${user.id}`, {}, props);
                await updateUserData();
            }
        },
        {
            id: 'company_website',
            title: 'Complete Company Website',
            description: 'Add your company website',
            icon: <TbWorldWww />,
            isComplete: !!(company?.website),
            isOptional: true,
            component: <StepCompanyWebsite />,
            action: async (props) => {
                setCompany(prev => ({ ...prev, ...props }));
                await Api.put(`/companies/${company.id}`, {}, props);
                if (props?.image?.url) await updateCompanyLogoUrl(props?.image?.url);
                updateUserData();
            }
        },
        {
            id: 'company_profile',
            title: 'Complete Company Profile',
            description: 'Add your company description',
            icon: <TbBuildingStore />,
            isComplete: !!(company?.name && company?.description),
            component: <StepCompanyProfile />,
            validate: (props) => {
                props = { ...company, ...props };
                if (!props?.name?.trim()) {
                    return 'Please enter your company name';
                }
                if (!props?.description?.trim()) {
                    return 'Please enter your company description';
                }
                return true;
            },
            action: async (props) => {
                await Api.put(`/companies/${company.id}`, {}, props);
                setCompany(prev => ({ ...prev, ...props }));
                updateUserData();
            }
        },
        {
            id: 'brand_voice',
            title: 'Set Company Brand Voice',
            description: 'Set your tone, topics and audiences',
            icon: <TbSpeakerphone />,
            isComplete: !!(company?.language && (company?.audiences?.length || company?.topics?.length || company?.tones?.length || company?.language_types?.length)),
            component: <StepCompanyBrandVoice />,
            validate: (props) => {
                props = { ...company, ...props };
                if (!props?.language?.trim()) {
                    return 'Please select a language';
                }
                if (!props?.audiences?.length && !props?.topics?.length && !props?.tones?.length && !props?.language_types?.length) {
                    return 'Please select at least one audience, topic, tone and language type';
                }
                return true;
            },
            action: async (props) => {
                await Api.put(`/companies/${company.id}`, {}, props);
                setCompany(prev => ({ ...prev, ...props }));
                updateUserData();
            }
        },
        {
            id: 'brand_style',
            title: 'Set Company Brand Style',
            description: 'Set your tone, topics and audiences',
            icon: <TbPalette />,
            isComplete: !!(company?.image?.url && !!company?.palettes?.length),
            isOptional: true,
            component: <StepCompanyBrandStyle />,
            action: async (props) => {
                setCompany(prev => ({ ...prev, ...props }));
                await Api.put(`/companies/${company.id}`, {}, props);
                if (props?.image?.url && props?.image?.url != company?.image?.url) await updateCompanyLogoUrl(props?.image?.url);
            }
        },
        // {
        //     id: 'prompts',
        //     title: 'Setup Prompts',
        //     description: 'Configure content generation prompts',
        //     icon: <TbTargetArrow />,
        //     isComplete: company?.prompts?.length > 0,
        //     // action: () => navigate('/company-settings/prompts')
        // },
    ], [user, company, companySocials]);

    const nonOnboardingSteps = useMemo(() => {
        return [
            {
                id: 'social_networks',
                title: 'Connect Social Networks',
                description: 'Connect your social media accounts',
                icon: <TbNetwork />,
                isComplete: companySocials?.length > 0,
                component: <ConnectASocialNetworkModal />
            },
            // {
            //     id: 'team',
            //     title: 'Onboard Your Team',
            //     description: 'Invite team members to collaborate',
            //     icon: <HiOutlineUserGroup />,
            //     isComplete: company?.team_members?.length > 1,
            //     component: <StepCompanyTeam />,
            // }
        ]
    }, [user, company, companySocials]);

    const isCompleted = useMemo(() => {
        return steps.every(step => step.isOptional || step.isComplete);
    }, [steps]);

    const isFullCompleted = useMemo(() => {
        return fullCompletionPercentage === 100;
    }, [fullCompletionPercentage]);

    const open = (stepId = null) => {
        if (stepId) {
            const stepIndex = steps.findIndex(step => step.id === stepId);
            setCurrentStep(stepIndex !== -1 ? stepIndex : 0);
        } else {
            const firstIncompleteStep = steps.findIndex(step => !step.isComplete);
            setCurrentStep(firstIncompleteStep !== -1 ? firstIncompleteStep : 0);
        }
        localStorage.removeItem(`recentlyCreatedCompany`);
        setIsOpened(true);
    };

    const close = () => {
        setIsOpened(false);
    };

    const nextStep = async (props) => {
        if (currentStep < steps.length - 1) {
            const currentStepObj = steps[currentStep];
            
            // Skip validation for optional steps
            if (!currentStepObj.isOptional) {
                const validationResult = currentStepObj.validate?.(props);
                if (validationResult !== true) {
                    toast.push(
                        <Notification 
                            title="Validation Error"
                            type="warning"
                        >
                            {validationResult}
                        </Notification>
                    );
                    return;
                }
            }

            if (currentStepObj?.action) {
                await currentStepObj.action(props);
            }
            setCurrentStep(prev => prev + 1);
        } else {
            close();
        }
    };

    const prevStep = () => {
        if (currentStep > 0) {
            setCurrentStep(prev => prev - 1);
        }
    };

    const goToStep = (stepId) => {
        const stepIndex = steps.findIndex(step => step.id === stepId);
        if (stepIndex !== -1) {
            setCurrentStep(stepIndex);
        }
    };

    useEffect(() => {
        if (!!company?.id && !isLoadingCompanySocials) {
            const completedSteps = steps.filter(step => step.isComplete).length;
            setCompletionPercentage(Math.round((completedSteps / steps.length) * 100));
            setIsLoading(false);
        }
    }, [steps, company, isLoadingCompanySocials]);

    useEffect(() => {
        if (!!company?.id && !isLoadingCompanySocials) {
            const completedSteps = [...steps, ...nonOnboardingSteps].filter(step => step.isComplete).length;
            setFullCompletionPercentage(Math.round((completedSteps / [...steps, ...nonOnboardingSteps].length) * 100));
            setIsLoading(false);
        }
    }, [steps, nonOnboardingSteps, company, isLoadingCompanySocials]);

    useEffect(() => {
        if (isLoading) {
            close();
            return;
        }
        if (
            !window.location.pathname.includes("appsumo") && 
            !window.location.pathname.includes("subscription") &&  
            !window.location.pathname.includes("settings")
        ) {
            if (
                (COMPANY_ONBOARDING_REQUIRED && !isOpened && !isCompleted && user?.companies?.length == 1) || 
                (user?.companies?.length && !isOpened && !!localStorage.getItem(`recentlyCreatedCompany`) && !isCompleted)
            ) {
                open();
                return;
            }
        }
    }, [company, steps, isCompleted, isLoading, user?.companies?.length]);

    return (
        <CompanyOnboardingContext.Provider value={{ 
            isOpened,
            currentStep,
            steps,
            nonOnboardingSteps,
            completionPercentage,
            fullCompletionPercentage,
            isCompleted,
            isFullCompleted,
            open,
            close,
            nextStep,
            prevStep,
            goToStep
        }}>
            {children}
        </CompanyOnboardingContext.Provider>
    );
};

export { CompanyOnboardingContext, CompanyOnboardingProvider };