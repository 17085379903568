import InputModern from 'components/ui/Input/InputModern';
import { AIContext } from 'contexts/AIContext';
import React, { useContext, useState } from 'react';
import CompanyOnboardingModalFooter from './CompanyOnboardingModalFooter';
import { formatUrl, getHTMLByURL, getReadableByURL } from 'utils/getReadableByURL';
import { CompanyContext } from 'contexts/CompanyContext';
import { Button } from 'components/ui';
import { isValidUrl } from 'utils/validate';
import { proxyUrl } from 'utils/proxy';
import { CompanyOnboardingContext } from 'contexts/CompanyOnboardingContext';
import DynamicLoading, { websiteScrapLoadingMessages } from 'components/shared/DynamicLoading';
import toImg from 'react-svg-to-image';
import { extractDominantColors } from 'utils/colorUtils';
import { languageOptions } from 'views/company_settings/components/BrandVoice';

export default function StepCompanyWebsite() {

  const { company } = useContext(CompanyContext);
  const { nextStep } = useContext(CompanyOnboardingContext);
  const { getByQueryChat, aiModels } = useContext(AIContext);
  const [ website, setWebsite ] = useState(company?.website);
  const [ isLoading, setIsLoading ] = useState(false);
  const [ data, setData ] = useState({
    name: company?.name,
    description: company?.description,
    image: company?.image,
    language_iso_code: company?.language_iso_code,
    language: company?.language,
    audience_types: company?.audience_types,
    interests: company?.interests,
    language_types: company?.language_types,
    palettes: company?.palettes,
    tones: company?.tones,
    emotions: company?.emotions,
    syntaxes: company?.syntaxes,
    characters: company?.characters,
  });

  const getBase64FromUrl = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob); 
        reader.onloadend = () => {
          const base64data = reader.result;   
          resolve(base64data);
        }
        reader.onerror = () => {
          reject(new Error('Failed to read blob as base64'));
        }
      });
    } catch (err) {
      console.error(`Failed to fetch image from ${url}:`, err);
      throw err; // Re-throw to be caught by caller
    }
  }

  const generateNameAndDescription = async (websiteContent) => {    
    if (websiteContent?.length > 200) {
      const response = await getByQueryChat(
        `
          Website content from ${website}: 
          ${websiteContent}
        `,  
        false, 
        true, 
        `
          When you are given a website content you should answer a valid JSON with the following information:

          Generate a detailed, objective, and professional description for the company.
            - The description must start with: "{company name} is a".
            - The description should be detailed, factual, and clear, and as long as possible with a maximum length of 3,000 characters.
            - Separate into multiple paragraphs for enhanced readability.

          Additionally, extract the following details:
            - Company Name: Extract the company name from the content.
            - Website Logo URL: Provide the logo's absolute URL if found, otherwise return null or empty. Use the base URL (${website}) for reference.
            - Audience Types: List the intended audiences the content addresses.
            - Interests: List the interests or topics the content addresses.
            - Tones: Identify the tone(s) used in the content.
            - Emotions: Identify any emotional appeals present in the content.
            - Syntaxes: Return an array describing how the content is structured, such as writing style, clarity, actionability, and organization. Minimum of 3 words per syntax.
            - Language Types: Specify the type of language used, maximum of 2 words per language type.
            - Characters: A brand's character is the set of human characteristics that define the brand.
            - Language ISO: The language code (e.g., en, es).
            - Language Name: The full name of the language (e.g., English, Spanish).
        `, 
        undefined, 
        undefined, 
        undefined, 
        aiModels.find(e => !!e?.sysDefault)?.model, 
        {
          "name": "website_content",
          "schema": {
            "type": "object",
            "properties": {
              "company_name": {
                "type": "string",
                "description": "The name of the company."
              },
              "company_description": {
                "type": "string",
                "description": "A description of the company, as long as possible up to 3000 characters. Separate into multiple paragraphs for enhanced readability."
              },
              "audience_types": {
                "type": "array",
                "description": "List of target audiences.",
                "items": {
                  "type": "string"
                }
              },
              "interests": {
                "type": "array",
                "description": "List of interests or topics the content addresses.",
                "items": {
                  "type": "string"
                }
              },
              "tones": {
                "type": "array",
                "description": "Tone used in the content, can be one or more ",
                "items": {
                  "type": "string",
                }
              },
              "emotions": {
                "type": "array",
                "description": "Emotional tone of the content, can be one or more ",
                "items": {
                  "type": "string"
                }
              },
              "syntaxes": {
                "type": "array",
                "description": "Syntax styles that should be followed, can be one or more ",
                "items": {
                  "type": "string"
                }
              },
              "language_types": {
                "type": "array",
                "description": "The type of language to use ",
                "items": {
                  "type": "string"
                }
              },
              "characters": {
                "type": "array",
                "description": "A brand's character is the set of human characteristics that define the brand.",
                "items": {
                  "type": "string"
                }
              },
              "language_iso_code": {
                "type": "string",
                "description": "ISO language code (e.g., en, es)."
              },
              "language": {
                "type": "string",
                "description": "Human-readable name of the language (e.g., English, Spanish)."
              }
            },
            "required": [
              "company_name",
              "company_description",
              "audience_types",
              "interests",
              "tones",
              "emotions",
              "syntaxes",
              "language_types",
              "characters",
              "language_iso_code",
              "language"
            ],
            "additionalProperties": false
          },
          "strict": true
        }
      );
      
      if (response) {
        let {  
          company_name,
          company_description,
          audience_types,
          interests,
          tones,
          emotions,
          syntaxes,
          language_types,
          characters,
          language_iso_code,
          language
        } = JSON.parse(response);

        let finalImage = undefined;

        // Wrap all image fetching logic in a single try-catch to ensure we continue
        try {
          // // Try getting image from original URL first
          // if (image && !image?.toLowerCase()?.includes('.svg')) {
          //   try {
          //     console.log("Fetching image from original URL", image);
          //     finalImage = await getBase64FromUrl(proxyUrl(image));
          //   } catch (err) {
          //     console.error('Failed to fetch original image:', err);
          //   }
          // }

          // If still no image, try favicon
          if (!finalImage) {
            try {
              console.log("Fetching favicon from Google's favicon service", website);
              finalImage = await getBase64FromUrl(proxyUrl(`https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${website}&size=256`));
            } catch (err) {
              console.error('Failed to fetch favicon:', err);
            }
          }
          
          // If first attempt failed, try Clearbit
          if (!finalImage) {
            try {
              console.log("Fetching logo from Clearbit API", website);
              finalImage = await getBase64FromUrl(`https://logo.clearbit.com/${website}?size=256`);
            } catch (err) {
              console.error('Failed to fetch Clearbit image:', err);
            }
          }
        } catch (err) {
          console.error('Error in image fetching process:', err);
          // Continue without image
        }

        let palettes = data?.palettes || [];
        if (!palettes?.length && finalImage) {
          try {
            palettes = await extractDominantColors(finalImage);
          } catch (err) {
            console.error('Failed to extract colors:', err);
          }
        }

        const defaultLanguage = languageOptions?.[0];
        let _language_iso_code = language_iso_code?.toString()?.replace(/[^a-zA-Z]/g, '') || defaultLanguage?.iso_code;
        if (!languageOptions?.find(e => e?.iso_code == _language_iso_code)) {
          _language_iso_code = defaultLanguage?.iso_code;
        }
        let _language = languageOptions.find(e => e?.iso_code == _language_iso_code)?.value;

        const newData = {
          ...data,
          name: company_name,
          description: company_description,
          image: finalImage ? { url: finalImage } : undefined,
          audience_types: audience_types || data?.audience_types,
          interests: interests || data?.interests,
          tones: tones || data?.tones,
          emotions: emotions || data?.emotions,
          syntaxes: syntaxes || data?.syntaxes,
          language_types: language_types || data?.language_types,
          characters: characters || data?.characters,
          language_iso_code: _language_iso_code,
          language: _language,
          palettes: palettes || data?.palettes,
          website: website
        }
        setData(newData);

        return newData;
      }
    }

    setIsLoading(true);
  }

  const generate = async () => {
    if (!website) return undefined;
    try {
      const { textContent } = await getReadableByURL(formatUrl(website));
      const data = await generateNameAndDescription(textContent);
      return data;
    } catch (err) {
      console.error(err);
    }
  };

  const onNextStep = async () => {
    try {
      setIsLoading(true);
      const data = await generate();
      await nextStep(data);
    } catch (err) {
      if (isValidUrl(formatUrl(website))) {
        nextStep({ website: formatUrl(website) });
      } else {
        nextStep();
      }
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <>
      {isLoading ? (
        <>
          <DynamicLoading 
            messages={websiteScrapLoadingMessages} 
            interval={4000}
            className="min-h-[200px]"
          />
          {/* Dummy to justify between */}
          <span></span> 
        </>
      ) : (
        <>
          <div className="flex flex-col gap-2">
            <div className='lg:hidden text-center text-5xl mb-4'>🌐</div>
            <h3 className='text-gray-900 mega-title text-center lg:text-left pb-0 text-2xl lg:!text-2xl'>
              Tell us your website <span className='hidden lg:inline'>&nbsp;🌐</span>
            </h3>
            <h6 className='text-gray-700 font-normal text-center lg:text-left text-base lg:text-sm pb-4 lg:text-base'>
              Enter your website so Followr's AI can generate your brand voice, style and description automatically.
            </h6>

            <InputModern 
              name="website"
              placeholder="https://your-company.com"
              className="!w-full !px-4 !py-3 text-sm text-center lg:text-left"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onNextStep();
                }
              }}
            />
          </div>

          <CompanyOnboardingModalFooter 
            loading={isLoading}
            onNextStep={onNextStep}
          />
        </>
      )}
    </>
  );
}
