import InputModern from 'components/ui/Input/InputModern';
import React, { useContext, useState, useRef, useEffect } from 'react';
import CompanyOnboardingModalFooter from './CompanyOnboardingModalFooter';
import { Trans } from 'react-i18next';
import { CompanyContext } from 'contexts/CompanyContext';
import autosize from 'autosize';
import CreatableSelect from 'react-select/creatable'
import { Select } from 'components/ui';
import { CustomControl, CustomSelectOption } from 'views/company_settings/components/CompanyProfile';
import { languageOptions } from 'views/onboarding/StepCompanyLanguage';

export default function StepCompanyBrandVoice() {
  const { company } = useContext(CompanyContext);
  const [ interests, setInterests ] = useState(company?.interests || []);
  const [ audience_types, setAudienceTypes ] = useState(company?.audience_types || []);
  const [ tones, setTones ] = useState(company?.tones || []);
  const [ emotions, setEmotions ] = useState(company?.emotions || []);
  const [ syntaxes, setSyntaxes ] = useState(company?.syntaxes || []);
  const [ language_types, setLanguageTypes ] = useState(Array.isArray(company?.language_types) ? company?.language_types : []);
  const [ language_iso_code, setLanguageIsoCode ] = useState(company?.language_iso_code || '');
  const [ language, setLanguage ] = useState(company?.language || '');
  const [ characters, setCharacters ] = useState(company?.characters || []);

  const [ audienceTypesOptions, setAudienceTypesOptions ] = useState((Array.isArray(audience_types) ? audience_types : []).map(e => ({ value: e, label: e })));
  const [ interestsOptions, setInterestsOptions ] = useState((Array.isArray(interests) ? interests : []).map(e => ({ value: e, label: e })));
  const [ tonesOptions, setTonesOptions ] = useState((Array.isArray(tones) ? tones : []).map(e => ({ value: e, label: e })));
  const [ emotionsOptions, setEmotionsOptions ] = useState((Array.isArray(emotions) ? emotions : []).map(e => ({ value: e, label: e })));
  const [ syntaxesOptions, setSyntaxesOptions ] = useState((Array.isArray(syntaxes) ? syntaxes : []).map(e => ({ value: e, label: e })));
  const [ languageTypesOptions, setLanguageTypesOptions ] = useState((Array.isArray(language_types) ? language_types : []).map(e => ({ value: e, label: e })));
  const [ charactersOptions, setCharactersOptions ] = useState((Array.isArray(characters) ? characters : []).map(e => ({ value: e, label: e })));

  return (
    <>
      <div className="flex flex-col gap-2 overflow-y-auto">
        <div className='lg:hidden text-center text-5xl mb-4 scale-x-[-1]'>📢</div>
        <h3 className='text-gray-900 dark:text-gray-100 mega-title text-center lg:text-left pb-0 text-2xl lg:!text-2xl'>
          Brand Voice &nbsp;<span className="hidden lg:inline scale-x-[-1] inline-block">📢</span>
        </h3>
        <h6 className='text-gray-600 font-normal text-center lg:text-left text-sm pb-4 lg:text-base'>
          Set your brand voice and tone to help Followr's AI generate content that matches your company's communication style.
        </h6>

        <div className='flex flex-col gap-4 lg:gap-2'>
          <div className='flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Language 🏳️</label>
            <Select
              options={languageOptions}
              components={{
                Option: CustomSelectOption,
                Control: CustomControl,
              }}
              value={languageOptions.find((option) => option.value === language) || languageOptions.find((option) => option.iso_code === language_iso_code)}
              onChange={(option) => {
                setLanguage(option.value)
                setLanguageIsoCode(option.iso_code)
              }}
            />
          </div>

          <div className='flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Topics 📌</label>
            <Select
              isMulti
              options={interestsOptions}
              componentAs={CreatableSelect}
              className='hide-indicators'
              value={interests?.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action == 'set-value') {
                  setInterestsOptions([...interestsOptions, { value: action.prevInputValue, label: action.prevInputValue }])
                }
              }}
              onChange={(option, action) => {
                if (action.action == 'remove-value') {
                  setInterestsOptions(interestsOptions.filter(e => e.value != action.removedValue.value))
                }
                setInterests(option.map(item => item.value))
              }}
            />
          </div>

          <div className='flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Audiences 🎯</label>
            <Select
              isMulti
              options={audienceTypesOptions}
              componentAs={CreatableSelect}
              className='hide-indicators'
              value={audience_types?.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action == 'set-value') {
                  setAudienceTypesOptions([...audienceTypesOptions, { value: action.prevInputValue, label: action.prevInputValue }])
                }
              }}
              onChange={(option, action) => {
                if (action.action == 'remove-value') {
                  setAudienceTypesOptions(audienceTypesOptions.filter(e => e.value != action.removedValue.value))
                }
                setAudienceTypes(option.map(item => item.value))
              }}
            />
          </div>

          <div className='flex flex-col lg:flex-row lg:items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Tones 🎤</label>
            <Select
              isMulti
              options={tonesOptions}
              componentAs={CreatableSelect}
              className='hide-indicators'
              placeholder='Write your tones and press enter to add.'
              value={tones?.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action == 'set-value') {
                  setTonesOptions([...tonesOptions, { value: action.prevInputValue, label: action.prevInputValue }])
                }
              }}
              onChange={(option, action) => {
                if (action.action == 'remove-value') {
                  setTonesOptions(tonesOptions.filter(e => e.value != action.removedValue.value))
                }
                setTones(option.map(item => item.value))
              }}
            />
          </div>

          {/* <div className='flex flex-col lg:flex-row items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Emotions 🎭</label>
            <Select
              isMulti
              options={emotionsOptions}
              componentAs={CreatableSelect}
              className='hide-indicators'
              placeholder='Write your company emotions and press enter to add.'
              value={emotions?.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action == 'set-value') {
                  setEmotionsOptions([...emotionsOptions, { value: action.prevInputValue, label: action.prevInputValue }])
                }
              }}
              onChange={(option, action) => {
                if (action.action == 'remove-value') {
                  setEmotionsOptions(emotionsOptions.filter(e => e.value != action.removedValue.value))
                }
                setEmotions(option.map(item => item.value))
              }}
            />
          </div> */}

          {/* <div className='flex flex-col lg:flex-row items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Syntax 📝</label>
            <Select
              isMulti
              options={syntaxesOptions}
              componentAs={CreatableSelect}
              className='hide-indicators'
              placeholder='Write your company syntax and press enter to add.'
              value={syntaxes?.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action == 'set-value') {
                  setSyntaxesOptions([...syntaxesOptions, { value: action.prevInputValue, label: action.prevInputValue }])
                }
              }}
              onChange={(option, action) => {
                if (action.action == 'remove-value') {
                  setSyntaxesOptions(syntaxesOptions.filter(e => e.value != action.removedValue.value))
                }
                setSyntaxes(option.map(item => item.value))
              }}
            />
          </div> */}

          <div className='hidden lg:flex flex-col lg:flex-row items-center gap-2 lg:gap-4'>
            <label className='text-gray-900 dark:text-gray-400 font-semibold text-sm px-2 lg:px-0 whitespace-nowrap min-w-[130spx]'>Language Types 🗣️</label>
            <Select
              isMulti
              options={languageTypesOptions}
              componentAs={CreatableSelect}
              className='hide-indicators'
              placeholder='Write your company language types and press enter to add.'
              value={language_types?.map(e => ({ value: e, label: e }))}
              ClearIndicator={() => null}
              onInputChange={(_, action) => {
                if (action.action == 'set-value') {
                  setLanguageTypesOptions([...languageTypesOptions, { value: action.prevInputValue, label: action.prevInputValue }])
                }
              }}
              onChange={(option, action) => {
                if (action.action == 'remove-value') {
                  setLanguageTypesOptions(languageTypesOptions.filter(e => e.value != action.removedValue.value))
                }
                setLanguageTypes(option.map(item => item.value))
              }}
            />
          </div>
        </div>

      </div>

      <CompanyOnboardingModalFooter 
        props={{
          language_iso_code: language_iso_code || 'en',
          language: language || 'English (US)',
          interests: interests?.length ? interests : [],
          audience_types: audience_types?.length ? audience_types : [],
          tones: tones?.length ? tones : [],
          emotions: emotions?.length ? emotions : [],
          syntaxes: syntaxes?.length ? syntaxes : [],
          language_types: language_types?.length ? language_types : [],
          characters: characters?.length ? characters : []
        }}
      />
    </>
  );
}
