import classNames from "classnames";
import { FormContainer, FormItem, Input, Segment } from "components/ui";
import { Field } from "formik";
import React, { useEffect } from "react";
import { Trans } from "react-i18next";
import { HiCheckCircle } from "react-icons/hi";

export const companyTypeOptions = [
    { 
        value: "Company",
        name: <Trans i18nKey={`companyType.company`}>Company</Trans>, 
        desc: <Trans i18nKey={`companyType.companyDesc`}>I will post on behalf of a company.</Trans>, 
        disabled: false, 
        emoji: "🏢" },
    { 
        value: "Individual",
        name: <Trans i18nKey={`companyType.individual`}>Individual</Trans>, 
        desc: <Trans i18nKey={`companyType.individualDesc`}>I will post on behalf of an individual.</Trans>, 
        disabled: false, 
        emoji: "👨" 
    },
];

const StepCompanyType = ({ form, setForm, errors }) => {

    const options = companyTypeOptions;

    useEffect(() => {
        if (!form?.company_type) {
            setForm({...form, company_type: "Company"});
        }
    }, []);

    return (
        <div className="w-full">
            {/* <h1 className="text-[1.55rem] lg:text-4xl mega-title text-center">{title}</h1> */}
            {/* <p className="text-center !text-sm lg:!text-base mt-1 lg:mt-4">{subtitle}</p> */}

            <Segment defaultValue={form?.company_type} className="gap-4 flex-col justify-center w-full">
                {options.map((item, index) => (
                    <Segment.Item
                        name={item.name}
                        value={item.value}
                        key={item.value}
                        disabled={item.disabled}
                    >
                        {({ ref, active, name, value, onSegmentItemClick, disabled }) => {
                            return (
                                <div
                                    ref={ref}
                                    className={classNames(
                                        'flex',
                                        'relative',
                                        'ring-1',
                                        'justify-between',
                                        'border',
                                        'rounded-3xl ',
                                        errors?.company_type
                                            ? 'border-red-600'
                                            : 'border-gray-300',
                                        'py-1.5 px-1 lg:py-2 lg:px-2',
                                        'cursor-pointer',
                                        'select-none',
                                        'w-100',
                                        'md:w-full',
                                        'bg-white',
                                        active
                                            ? 'ring-transparent border border-[4px] border-primary-600 !bg-primary-50'
                                            : 'ring-transparent',
                                        disabled
                                            ? 'opacity-50 cursor-not-allowed'
                                            : 'hover:ring-cyan-500 hover:border-cyan-500'
                                    )}
                                    onClick={() => {
                                        onSegmentItemClick();
                                        setForm({...form, company_type: value});
                                    }}
                                >
                                    <div className="gap-3 lg:gap-6 px-4 py-2 flex flex-row justify-center items-center w-full">
                                        <div className="w-12 h-12 bg-primary-600/30 border-4 border-primary-600/10 flex items-center justify-center rounded-full text-2xl">
                                            {item.emoji}
                                        </div>
                                        <div className="gap-0 flex flex-col flex-1">
                                            <h6 className="text-left mega-title !text-base -mt-1 !text-gray-900">{name}</h6>
                                            <p className="!text-xs lg:!text-sm text-left">{item.desc}</p>
                                        </div>
                                        <HiCheckCircle className={`text-gray-200 text-2xl ${active && `!text-primary-600`}`} />
                                    </div>
                                </div>
                            )
                        }}
                    </Segment.Item>
                ))}
            </Segment>
            {errors?.company_type &&
                <p className="text-center text-red-600">{errors?.company_type}</p>
            }
        </div>
    )
}

export default StepCompanyType;