import { Loading } from "components/shared";
import { Button, Notification, toast } from "components/ui";
import React, { useContext, useState } from "react";
import { SessionContext } from "./SessionContext";
import { CompanyContext } from "./CompanyContext";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";
import getSocialNetworkProfileLink from "utils/getSocialNetworkProfileLink";
import capitalize from 'components/ui/utils/capitalize';
import { replaceAll } from "utils/replaceAll";
import Api from "services/Api";
import { get } from "lodash";
import { useSelector } from "react-redux";
import { AppSumoContext } from "./AppSumoContext";
import { stripMarkdown } from "utils/stripMarkdown";
import { CompanyOnboardingContext } from "./CompanyOnboardingContext";

import openaiImg from "../assets/images/drivers/openai.png";
import claudeImg from "../assets/images/drivers/claude.png";
import stabilityImg from "../assets/images/drivers/stability.png";
import ideogramImg from "../assets/images/drivers/ideogram.png";
import geminiImg from "../assets/images/drivers/gemini.png";
import falImg from "../assets/images/drivers/fal.png";
import recraftImg from "../assets/images/drivers/recraft.png";
import deepseekImg from "../assets/images/drivers/deepseek.png";


export const aiSizeOptions = [
  { 
    id: "1:1",
    aspect_ratio: "1:1", 
    label: "Square (1:1)",
    category: "square"
  },
  { 
    id: "4:3",
    aspect_ratio: "4:3", 
    label: "Landscape (4:3)",
    category: "landscape"
  },
  { 
    id: "3:2",
    aspect_ratio: "3:2", 
    label: "Landscape (3:2)",
    category: "landscape"
  },
  { 
    id: "16:9",
    aspect_ratio: "16:9", 
    label: "Landscape (16:9)",
    category: "landscape"
  },
  { 
    id: "3:4",
    aspect_ratio: "3:4", 
    label: "Portrait (3:4)",
    category: "portrait"
  },
  { 
    id: "2:3",
    aspect_ratio: "2:3", 
    label: "Portrait (2:3)",
    category: "portrait"
  },
  { 
    id: "9:16",
    aspect_ratio: "9:16", 
    label: "Portrait (9:16)",
    category: "portrait"
  },
];

const AIContext = React.createContext({
  getDriverModel: (type) => void 0,
  getImages: async (query = "", size = "1:1", _model = undefined, addColors = false) => void 0,
  getByQueryChat: async (query = "", isChargable = true, forceNewQuerySystem = false, newQuerySystem = "", aiResultId = undefined, config = {}, groupId = undefined, _model = undefined) => void 0, 
  getLastHistoryAIImageURL: async () => void 0,
  aiModels: [],
  availableAiModels: [],
  aiTextModels: [],
  aiImageModels: [],
});

const AIProvider = ({ children }) => {
  
  const { updateBalance } = useContext(SessionContext);
  const { company } = useContext(CompanyContext);
  const { isSumoling, hasPremiumImages } = useContext(AppSumoContext);
  const { open: openCompanyOnboarding, isCompleted: companyOnboardingCompleted } = useContext(CompanyOnboardingContext);
  const locale = useSelector((state) => state.locale.currentLang);

  const hasDriverActiveExternalApiKey = (driver) => {
    return !!company?.ai_keys?.find(e => e?.driver == driver)?.value && !!company?.ai_keys?.find(e => e?.driver == driver)?.active
  }
  
  const aiModels = [
    {
      type: 'text',
      driver: 'openai',
      driverDisplayName: 'OpenAI',
      image: openaiImg,
      model: 'gpt-4o-mini',
      name: 'GPT-4o mini',
      fullName: 'OpenAI GPT-4o mini',
      price: 1,
      sysDefault: true
    },
    {
      type: 'text',
      driver: 'openai',
      driverDisplayName: 'OpenAI',
      image: openaiImg,
      model: 'gpt-4o',
      name: 'GPT-4o',
      fullName: 'OpenAI GPT-4o',
      best: true,
      price: 10,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('openai')
    },
    {
      type: 'text',
      driver: 'openai',
      driverDisplayName: 'OpenAI',
      image: openaiImg,
      model: 'o1-mini',
      name: 'o1-mini',
      fullName: 'OpenAI o1-mini',
      best: true,
      price: 10,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('openai'),
    },
    {
      type: 'text',
      driver: 'deepseek',
      driverDisplayName: 'DeepSeek',
      image: deepseekImg,
      model: 'deepseek-chat',
      name: 'DeepSeek Chat',
      fullName: 'DeepSeek Chat',
      best: true,
      price: 2,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('deepseek'),
    },
    // {
    //   type: 'text',
    //   driver: 'deepseek',
    //   driverDisplayName: 'DeepSeek',
    //   image: deepseekImg,
    //   model: 'deepseek-reasoner',
    //   name: 'DeepSeek Reasoner',
    //   fullName: 'DeepSeek Reasoner',
    //   best: true,
    //   price: 5,
    //   disabled: isSumoling() && !hasDriverActiveExternalApiKey('deepseek'),
    //   new: true
    // },
    // {
    //   type: 'text',
    //   driver: 'openai',
    //   driverDisplayName: 'OpenAI',
    //   image: openaiImg,
    //   model: 'o1-preview',
    //   name: 'o1-preview',
    //   fullName: 'OpenAI o1-preview',
    //   best: true,
    //   price: 20,
    //   disabled: isSumoling() && !hasDriverActiveExternalApiKey('openai'),
    //   new: true
    // },
    {
      type: 'text',
      driver: 'claude',
      driverDisplayName: 'Anthropic',
      image: claudeImg,
      model: 'claude-3-haiku-20240307',
      name: 'Calude 3',
      fullName: 'Claude 3 Haiku',
      price: 1,
    },
    {
      type: 'text',
      driver: 'claude',
      driverDisplayName: 'Anthropic',
      image: claudeImg,
      model: 'claude-3-5-haiku-20241022',
      name: 'Calude 3.5',
      fullName: 'Claude 3.5 Haiku',
      price: 5,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('claude'),
    },
    {
      type: 'text',
      driver: 'claude',
      driverDisplayName: 'Anthropic',
      image: claudeImg,
      model: 'claude-3-5-sonnet-20240620',
      name: 'Calude 3.5',
      fullName: 'Claude 3.5 Sonnet',
      best: false,
      price: 10,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('claude')
    },
    {
      type: 'text',
      driver: 'claude',
      driverDisplayName: 'Anthropic',
      image: claudeImg,
      model: 'claude-3-7-sonnet-20250219',
      name: 'Calude 3.7',
      fullName: 'Claude 3.7 Sonnet',
      best: true,
      price: 10,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('claude'),
      new: true
    },
    {
      type: 'text',
      driver: 'gemini',
      driverDisplayName: 'Gemini',
      image: geminiImg,
      model: 'gemini-1.5-flash',
      name: 'Gemini 1.5 Flash',
      fullName: 'Gemini 1.5 Flash',
      price: 1,
    },
    {
      type: 'text',
      driver: 'gemini',
      driverDisplayName: 'Gemini',
      image: geminiImg,
      model: 'gemini-1.5-pro',
      name: 'Gemini 1.5 Pro',
      fullName: 'Gemini 1.5 Pro',
      price: 10,
      disabled: isSumoling() && !hasDriverActiveExternalApiKey('gemini')
    },

    {
      type: 'image',
      driver: 'openai',
      driverDisplayName: 'OpenAI',
      image: openaiImg,
      model: 'dall-e-3',
      name: 'DALL·E 3',
      fullName: 'OpenAI DALL·E 3',
      availableSizes: ["1:1", "16:9", "9:16"],
      price: 1
    },
    {
      type: 'image',
      driver: 'fal',
      driverDisplayName: 'Fal',
      image: falImg,
      model: 'flux_dev',
      name: 'Flux.1 Dev',
      fullName: 'Fal Flux.1 Dev',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4"],
      price: 2,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('fal')
    },
    // {
    //   type: 'image',
    //   driver: 'fal',
    //   driverDisplayName: 'Fal',
    //   image: falImg,
    //   model: 'flux_realism',
    //   name: 'Flux.1 Realism',
    //   fullName: 'Fal Flux.1 Realism',
    //   needsTranslation: true,
    //   availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4"],
    //   price: 5,
    //   disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('fal')
    // },
    {
      type: 'image',
      driver: 'fal',
      driverDisplayName: 'Fal',
      image: falImg,
      model: 'flux_pro_1.1',
      name: 'Flux Pro 1.1',
      fullName: 'Fal Flux Pro 1.1',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4"],
      price: 5,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('fal'),
    },
    {
      type: 'image',
      driver: 'fal',
      driverDisplayName: 'Fal',
      image: falImg,
      model: 'flux_pro_1.1_ultra',
      name: 'Flux Pro 1.1 Ultra',
      fullName: 'Fal Flux Pro 1.1 Ultra',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4"],
      price: 10,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('fal'),
    },
    {
      type: 'image',
      driver: 'recraft',
      driverDisplayName: 'Recraft',
      image: recraftImg,
      model: 'recraftv3',
      name: 'Recraft v3 - Digital',
      fullName: 'Recraft v3 - Digital',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4", "2:3", "3:2"],
      price: 3,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('recraft'),
      new: true,
      canIncludeColors: true,
      colorFormat: "rgb",
      prompts: [
        `
          COPY: {{copy}}
  
          Prompt for a digital illustration AI image generator to generate visually attractive social media posts based on the given copy. 
          Do not specify the colors.
  
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
  
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.
  
          Skip the preambles.
  
          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Include a realistic background picture that complements the theme, overlaid with a bold, vibrant gradient layer with high opacity. Use bold, modern typography for the text. The text should be centrally aligned and minimalistic.

          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Include a stylized abstract background with smooth shapes or patterns and a subtle gradient overlay. Keep the text floating in a clean, futuristic typeface. The design should feel dynamic and energetic.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Include a photorealistic textured background image (e.g., fabric, paper, or wood) with the copy written over it in an elegant, serif font. Add a slight vignette effect to focus attention on the text.

          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Use a minimalistic geometric background with a monochromatic gradient. Text should appear bold and offset, with overlapping transparent text blocks for emphasis.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Feature a realistic blurred background image with the text placed over a sharp gradient-filled rectangle or circle. Use clean, sans-serif typography for a contemporary aesthetic.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Feature a realistic blurred background image with the text placed over a sharp gradient-filled rectangle or circle. Use clean, sans-serif typography for a contemporary aesthetic.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Include a collage-style design with layered cutout shapes, textures, and small icons around the text. Use bold, asymmetrical typography to give the post a playful, creative vibe. Text placement should be dynamic, with varying sizes and alignments.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Incorporate a dynamic 3D element, such as floating shapes or objects, with a subtle shadow effect. The text should interact with the 3D design, curving or wrapping around elements. Use a combination of gradient and metallic finishes for added depth.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `,
        `
          COPY: {{copy}}

          Prompt for a digital illustration AI image generator to create visually attractive social media posts based on the given copy. Focus on a hand-drawn sketch style for the main elements, such as icons, frames, or borders. Add a simple gradient in the background for contrast. Use handwritten or brushstroke-style typography to match the organic theme.
          
          Do not specify the colors.
          The prompt must be in English (EN) but the text in the generated image must be the language of the company. 
          If you include text, keep it very very very short.
          Be specific with what you want to generate. Do not describe the scene, just describe the elements you want to include.

          Skip the preambles.

          Prompt:
        `
      ]
    },
    // {
    //   type: 'image',
    //   driver: 'recraft',
    //   driverDisplayName: 'Recraft',
    //   image: recraftImg,
    //   model: 'recraftv3',
    //   name: 'Recraft v3 - Realistic',
    //   fullName: 'Recraft v3 - Realistic',
    //   needsTranslation: true,
    //   availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4", "2:3", "3:2"],
    //   price: 5,
    //   disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('recraft'),
    //   new: true,
    // },
    {
      type: 'image',
      driver: 'ideogram',
      driverDisplayName: 'Ideogram',
      image: ideogramImg,
      model: 'V_2_TURBO',
      name: '2.0 Turbo',
      fullName: 'Ideogram 2.0 Turbo',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4", "2:3", "3:2"],
      price: 2,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('ideogram')
    },
    {
      type: 'image',
      driver: 'ideogram',
      driverDisplayName: 'Ideogram',
      image: ideogramImg,
      model: 'V_2',
      name: '2.0',
      fullName: 'Ideogram 2.0',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4", "2:3", "3:2"],
      price: 5,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('ideogram')
    },
    {
      type: 'image',
      driver: 'stability',
      driverDisplayName: 'Stability',
      image: stabilityImg,
      model: 'core',
      name: 'Core',
      fullName: 'Stability Core',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4", "2:3", "3:2"],
      price: 2,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('stability')
    },
    {
      type: 'image',
      driver: 'stability',
      driverDisplayName: 'Stability',
      image: stabilityImg,
      model: 'ultra',
      name: 'Ultra',
      fullName: 'Stability Ultra',
      needsTranslation: true,
      availableSizes: ["1:1", "16:9", "9:16", "4:3", "3:4", "2:3", "3:2"],
      price: 5,
      best: true,
      disabled: !hasPremiumImages() && isSumoling() && !hasDriverActiveExternalApiKey('stability')
    },
  ];

  const availableAiModels = aiModels.filter(e => !e?.disabled);
  const aiTextModels = aiModels.filter(e => e.type == "text");
  const aiImageModels = aiModels.filter(e => e.type == "image");
  
  const systemMessage = !company?.id ? `` : `
${company?.name || `COMPANY: `}
${company?.description} 
${company?.audience_types && company?.audience_types?.length > 0 ? `Target audiences are: ${company?.audience_types.join(", ")}.` : ``} 
${company?.audience_genders && company?.audience_genders?.length > 0 ? `Target audience gender is: ${company?.audience_genders.join(", ")}.` : ``} 
${company?.audience_ages && company?.audience_ages?.length > 0 ? `Target audience age is: ${company?.audience_ages.join(", ")}.` : ``} 
${company?.website ? `Website is: ${company?.website}.` : ``}
${company?.interests && company?.interests?.length > 0 ? `Interests are: ${company?.interests.join(", ")}.` : ``} 
${company?.tones && company?.tones?.length > 0 ? `Tones are: ${company?.tones.join(", ")}.` : ``} 
${company?.emotions && company?.emotions?.length > 0 ? `Emotions are: ${company?.emotions.join(", ")}.` : ``} 
${company?.syntaxes && company?.syntaxes?.length > 0 ? `Syntaxes are: ${company?.syntaxes.join(", ")}.` : ``} 
${company?.language_types && company?.language_types?.length > 0 ? `Language types are: ${company?.language_types.join(", ")}.` : ``} 
${company?.characters && company?.characters?.length > 0 ? `Characters are: ${company?.characters.join(", ")}.` : ``}
${company?.palettes && company?.palettes?.length > 0 ? `Company colors are: ${company?.palettes.join(", ")}.` : ``}

--------------------------------------

You are the best copywriter of the world. You represent ${company?.name || `COMPANY`}. Your goal is to reach the target audience using the language, tone, emotion, syntax, character and language type you have been provided.

You must always write in ${company?.language || `language ISO code: ${locale}`}.

Do not use markdown notation.
When inserting URLs, insert them without brackets or placeholders, just write the URL.

Today is ${new Date().toUTCString()}.
  `;
  
  const getDriverModel = (modelType) => {
    const defaultModel = modelType == "text" 
      ? process.env.REACT_APP_DEFAULT_TEXT_AI_MODEL
      : process.env.REACT_APP_DEFAULT_IMAGE_AI_MODEL
    let model = company?.ai_preferences?.[`${modelType}_model`] || defaultModel || undefined;
    
    let driver = availableAiModels.filter(e => e.type == modelType).find(e => e.model == model)?.driver || undefined;
    if (!availableAiModels.filter(e => e.type == modelType).find(e => e.model == model)) {
      model = availableAiModels.filter(e => e.type == modelType)?.[0]?.model;
      driver = availableAiModels.filter(e => e.type == modelType)?.[0]?.driver;
    }

    let size = company?.ai_preferences?.image_aspect_ratio || "1:1";

    return { model, driver, size }
  }
  
  const getByQueryChat = async (query = "", isChargable = true, forceNewQuerySystem = false, newQuerySystem = "", aiResultId = undefined, config = {}, groupId = undefined, _model = undefined, jsonSchema = undefined) => {
  
    if (isChargable && !companyOnboardingCompleted) {
      toast.push(
        <Notification 
          width={400} 
          closable={true} 
          duration={10000} 
          title={`Onboarding required`}
          type="info" 
        >
          Followr needs more information about your company to generate more accurate and personalized content. Please complete the onboarding process.
        </Notification>
      , {
        placement: "top-center",
      });
      openCompanyOnboarding();
      return;
    }

    const q_system = forceNewQuerySystem 
      ? `
        ${newQuerySystem}
      `
      : `
        ${systemMessage}

        ${newQuerySystem}
      `;
    
    const { driver: driver, model: model } = aiModels.find(e => e.model == (_model)) || getDriverModel("text");
    
    const response = await Api.post(`/aiResults/chat`, {}, {
      q: query,
      q_system: q_system,
      company_id: company ? company?.id : undefined,
      chargeable: isChargable ? 1 : 0,
      group_id: groupId ? groupId : undefined,
      driver: isChargable || !!_model ? driver : undefined,
      model: isChargable || !!_model ? model : undefined,
      json_schema: jsonSchema ? JSON.stringify(jsonSchema) : undefined,
      response_format: jsonSchema ? "json_schema" : undefined,
      ...config
    }).catch((err) => {
      isChargable && toast.push(
        <Notification 
          width={400} 
          closable={true} 
          duration={10000} 
          title={
            <div className="flex flex-col gap-2">
              <b>AI Text Generation Error:</b>
              <p><i>{err?.message || err?.msg || err}</i></p>
              <p>Word credits have not been deducted from your account.</p>
            </div>
          }
          type="danger" 
        />
      );
    })
    
    let responseMessage = response.data.response.trim();
    responseMessage = stripMarkdown(responseMessage || ``);
    
    if (isChargable) {
      updateBalance();
    }
    
    return responseMessage;
  }
  
  const getImages = async (query = "", aspect_ratio = "1:1", _model = undefined, addColors = false) => {
    
    query = query.trim();

    const { driver: defaultDriver, model: defaultModel } = getDriverModel("image");
    const modelObj = availableAiModels.find(e => e.model == (_model || defaultModel) && e.type == 'image');

    const { driver, model } = modelObj || { driver: undefined, model: undefined };

    if (!modelObj?.availableSizes?.includes(aspect_ratio)) {  
      const category = aiSizeOptions.find(e => e.id == aspect_ratio)?.category;
      aspect_ratio = aiSizeOptions.filter(e => modelObj.availableSizes.includes(e.id)).filter(e => e.category == category)?.[0]?.id;
    }

    const colors = addColors && modelObj?.canIncludeColors
      ? modelObj?.colorFormat == "rgb"
        ? company?.palettes?.map(hex => {
            const r = parseInt(hex.slice(1,3), 16);
            const g = parseInt(hex.slice(3,5), 16);
            const b = parseInt(hex.slice(5,7), 16);
            return {r, g, b};
          })
        : undefined // TODO: Add HEX colors
      : undefined
    
    const response = await Api.post(`/aiResults/image`, {}, {
      q: query,
      company_id: company ? company?.id : undefined,
      n: 1,
      chargeable: 1,
      aspect_ratio: aspect_ratio,
      driver: driver || defaultDriver,
      model: model || defaultModel,
      colors: colors
    }).catch((err) => {
      toast.push(
        <Notification 
          width={400} 
          closable={true} 
          duration={10000} 
          title={
            <div className="flex flex-col gap-2">
              <b>AI Image Generation Error:</b>
              <p><i>{err?.message || err?.msg || err}</i></p>
              <p>Image credits have not been deducted from your account.</p>
            </div>
          }
          type="danger" 
        />
      );
    });
    
    updateBalance();
    
    return {
      id: response.data.id,
      urls: response.data.response.trim().split(",")
    }
  }
  
  const getLastHistoryAIImageURL = async (id = null) => {
    const results = await Api.get(`/aiResults?include=images,images.thumbnail,user,user.image&sort=-created_at&filter[company_id]=${company?.id}&filter[type]=image`).then(({ data: results }) => results);
    const result = id 
    ? results?.find(r => r?.id == id)
    : results?.[0];
    return result?.images?.find(i => i?.status == "analyzed")?.url || result?.response?.split(",")?.[0] || null
  }
  
  return (
    <AIContext.Provider value={{ 
      getByQueryChat, 
      getDriverModel,
      getImages,
      getLastHistoryAIImageURL,
      aiModels,
      availableAiModels,
      aiTextModels,
      aiImageModels
    }}>
      {children}
    </AIContext.Provider>
  );
};

export { AIContext, AIProvider };