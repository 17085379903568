import React, { useContext } from 'react'
import { Dialog, Button, Steps } from 'components/ui'
import { ModalContext } from 'contexts/ModalContext';
import { CompanyContext } from 'contexts/CompanyContext';
import { HiChevronLeft, HiChevronRight } from 'react-icons/hi';
import { SessionContext } from 'contexts/SessionContext';
import { Trans } from 'react-i18next';
import { CompanyOnboardingContext } from 'contexts/CompanyOnboardingContext';
import CompanyOnboardingModalFooter from './CompanyOnboardingModalFooter';
import { COMPANY_ONBOARDING_REQUIRED } from 'components/ui/utils/constant';

const CompanyOnboardingModal = () => {
  const { 
    close, 
    steps,
    currentStep, 
    goToStep,
    nextStep, 
    prevStep, 
    isOpened 
  } = useContext(CompanyOnboardingContext);
  const { user } = useContext(SessionContext);

  if (!isOpened) return null;
  if (!user?.id) return null;

  const isFirstStep = currentStep === 0;
  const isLastStep = currentStep === steps.length - 1;

  return (
    <Dialog
      isOpen={isOpened}
      closable={!COMPANY_ONBOARDING_REQUIRED || user?.companies?.length > 1 || !!user?.subscription}
      onClose={close}
      onRequestClose={close}
      overlayClassName="flex w-full h-full items-center justify-center"
      className="!max-w-[100vw] lg:!max-w-[95vw]"
      contentClassName="!max-h-[100vh] lg:!max-h-[95vh] !rounded-none lg:!rounded-2xl w-[100vw] lg:!w-auto"
      width={900}
    >
      <div className='min-h-[400px] h-[100vh] lg:!h-auto flex items-stretch justify-center flex-col lg:flex-row gap-4 lg:px-6 w-auto -m-6 !overflow-hidden'>
        <div className='shrink-0 hidden lg:block w-[250px] relative'>
          <img src="/img/others/social-networks-asset.png" className='absolute right-0 drop-shadow !h-[118%] -mt-[20%] rotate-[5deg] mr-[50px] max-w-none' />
        </div>
        <div className='h-full flex flex-col justify-between gap-0 lg:gap-4 flex-1 shrink-0 pl-6 lg:pl-0 pr-6 lg:pr-6 py-8 !pb-6 relative'>
          <div className="mb-8 -mx-4">
            <Steps current={currentStep}>
              {steps.map((step, index) => (
                <Steps.Item 
                  key={step.id} 
                  title={step.title}
                  customIcon={step.icon}
                  onStepChange={() => goToStep(step.id)}
                />
              ))}
            </Steps>
          </div>
          
          {steps[currentStep]?.component || null}
        </div>
      </div>
    </Dialog>
  );
}

export default CompanyOnboardingModal