import { extractColorsFromSrc } from "extract-colors";
import { proxyUrl } from "./proxy";

export function colorIsLight(color) {
  const hex = String(color).replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
  return brightness > 200;
}

export async function extractDominantColors(url) {
  url = url?.includes('http') ? proxyUrl(url) : url;
  const blob = await fetch(url).then(res => res.blob());
  let colors = await extractColorsFromSrc(URL.createObjectURL(blob));
  
  // Calculate color dominance score
  colors = colors.map(color => {
    const { red: r, green: g, blue: b } = color;
    
    // Calculate color intensity (0-1)
    const intensity = (r + g + b) / (255 * 3);
    
    // Calculate saturation (0-1)
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    const saturation = max === 0 ? 0 : (max - min) / max;
    
    // Calculate dominance score
    // We weight area more heavily (0.6) than intensity (0.2) and saturation (0.2)
    const dominanceScore = (
      (color.area * 0.4) + 
      (intensity * 0.3) + 
      (saturation * 0.3)
    );
    
    return {
      ...color,
      dominanceScore
    };
  });

  // Sort by dominance score
  colors = colors.sort((a, b) => b.dominanceScore - a.dominanceScore);
  
  // Convert to hex and filter out white
  let hexColors = colors
    .map(item => item?.hex?.toUpperCase())
    .filter(hex => hex !== "#FFFFFF");
  
  // Limit to top 3 colors
  hexColors.length = Math.min(hexColors.length, 3);
  
  return hexColors;
}