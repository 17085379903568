import Popover from '@idui/react-popover';
import { Button, Tooltip } from 'components/ui'
import useUniqueId from 'components/ui/hooks/useUniqueId';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { TbCircleArrowUpFilled, TbLockBolt, TbRocket, TbSquareArrowUpFilled, TbSquareRoundedArrowUp, TbSquareRoundedArrowUpFilled } from 'react-icons/tb'
import { useOnClickOutside } from 'usehooks-ts';
import useDarkMode from 'utils/hooks/useDarkMode';
import PlusModal from './PlusModal';
import { ModalContext } from 'contexts/ModalContext';
import { BsStars } from 'react-icons/bs';
import { AppSumoContext } from 'contexts/AppSumoContext';
import Subscription from 'views/subscription';
import { PriorityModalContext } from 'contexts/PriorityModalContext';

export default function PlusBadge({
  className = "",
  wrapperClassName = "",
  feature = "",
  placement = "top",
  content = undefined
}) {

  const { openModal } = useContext(ModalContext);
  const { openModalPriority } = useContext(PriorityModalContext);
  const { isSumoling } = useContext(AppSumoContext);
  const [ isDark ] = useDarkMode();

  const handleOnClick = (e) => {
    if (isSumoling()) {
      openModal(<PlusModal />, 650, true, true, "relative !overflow-visible !overflow-y-visible !p-0 !bg-transparent")
    } else {
      openModalPriority(<Subscription isModal={true} />, 1200, true, true, `!p-0 !bg-gray-100 dark:!bg-gray-900 zoom-95`);
    }
    e?.stopPropagation();
  }

  return (
    <>
      <Popover
        placement={placement}
        arrowColor={isDark ? 'black' : 'white'}
        className={`max-w-[300px] !border-0 flex flex-col dark:bg-black dark:text-gray-50 bg-white drop-shadow-xl !p-0 text-gray-900`}
        content={ 
          <div  
            onClick={(e) => e.stopPropagation()}
            className='divide-y divide-gray-100 dark:divide-gray-700'
          >
            <div className='p-2.5 flex items-start gap-2'>
              <TbLockBolt 
                className={`${!isSumoling() ? 'text-primary-600' : 'text-fuchsia-500'} text-2xl shrink-0 mt-1`}
              />
              <span className='text-sm'>
                {isSumoling() ? (
                  <b>
                    Upgrade your account to Followr&nbsp;
                    <span className='plus text-ai'>Plus</span>&nbsp; 
                    <BsStars className='text-pink-500 inline-block -mt-1 mr-1' />
                    to unlock this feature.
                  </b>
                ) : (
                  <b>
                    Upgrade your account to unlock this feature.
                  </b>
                )}
                <span>&nbsp;Get <i>{feature}</i> and more!</span>
              </span>
            </div>
            <div className='p-2.5'>
              <Button
                variant={isSumoling() ? "ai" : "solid"}
                size="sm"
                className="w-full !rounded-xl flex items-center justify-center gap-1"
                onClick={handleOnClick}
              >
                {isSumoling() ? (
                  <>
                    Get Followr 
                    <span className='plus'>Plus</span>
                    <BsStars />
                  </>
                ) : (
                  <>
                    <TbRocket className='text-xl' />
                    Upgrade Now
                  </>
                )}
              </Button>
            </div>
          </div>
        }
      >
        {content || (
          <div className={`${wrapperClassName}`}>
            <TbLockBolt
              onClick={handleOnClick}
              className={`transition-all ${!isSumoling() ? 'text-primary-600 hover:text-primary-700' : 'text-fuchsia-500 hover:text-pink-500'} text-2xl cursor-pointer ${className}`} 
            />
          </div>
        )}
      </Popover>
    </>
  )
}
