import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Dialog, Dropdown, Input, Progress, Switcher, Upload } from 'components/ui'
import { ModalContext } from 'contexts/ModalContext';
import { CompanyContext } from 'contexts/CompanyContext';
import SocialNetworkSidePanel, { socialNetworks } from 'views/calendar/SocialNetworkSidePanel';
import { capitalize } from 'lodash';
import { useNavigate } from 'react-router-dom';
import { HiChevronDown } from 'react-icons/hi';
import { SessionContext } from 'contexts/SessionContext';
import CompanyToggle from './CompanyToggle';
import { Trans } from 'react-i18next';

const ConnectASocialNetworkModal = ({ arg }) => {

    const { user } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const { closeModal, openModal } = useContext(ModalContext);
    const companies = user?.companies;

    return (
        <div className='flex items-center justify-center flex-col lg:flex-row gap-4 px-6 w-auto -m-6 !overflow-hidden'>
          <div className='shrink-0 hidden lg:block -ml-[75px]'>
            <img src="/img/others/social-networks-asset.png" className='drop-shadow w-full max-w-[385px] -mt-[50px] -mb-[50px] -ml-[70px] rotate-[12deg] m-auto' />
          </div>
          <div className='flex flex-col gap-2 flex-1 shrink-0 pl-8 lg:pl-0 pr-8 lg:pr-6 py-12 pt-16 relative max-w-[100vw]'>
              <h3 className='text-gray-900 mega-title text-center lg:text-left pb-4 text-2xl lg:!text-3xl'>
                <Trans i18nKey={`connectSocialNetworkModal.title`}>
                  Let's connect your social networks to Followr.
                </Trans>
              </h3>
              <h6 className='text-gray-700 font-normal text-center lg:text-left pb-4 lg:pb-8 text-sm lg:text-base'>
                <Trans i18nKey={`connectSocialNetworkModal.subtitle`}>
                  Connect your social networks and let Followr do the work for you. Click on any social network below and start creating!
                </Trans>
              </h6>
              <SocialNetworkSidePanel 
                forceClickIntegrate={true}
                itemClassName='lg:flex-1' 
                className='lg:!p-0 max-w-[100%] !flex-wrap !w-full !border-0 !overflow-visible !justify-center z-[0]'
                innerClassName='flex !flex-row !flex-wrap !justify-center lg:!justify-start' 
                showPlus={false} 
                showSelectAll={false} 
              />
              {/* {companies?.length > 0 && 
                <CompanyToggle 
                  className="!flex !mx-auto"
                  RenderTitle={<span className='text-primary-600 text-xs pt-6 lg:pt-12 flex gap-1 cursor-pointer justify-end items-center'><Trans i18nKey={`connectSocialNetworkModal.swtichCompany`}>Switch to another company</Trans> <HiChevronDown /></span>}
                  showConfig={false}
                  showCreateNewCompany={false}
                  placement={"top-start"}
                />
              } */}
          </div>
        </div>
    );
}

export default ConnectASocialNetworkModal