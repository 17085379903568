import OutOfWordsModal from "components/layout/OutOfWordsModal";
import SelectPlanModal from "components/layout/SelectPlanModal";
import { CompanyContext } from "contexts/CompanyContext";
import { PriorityModalContext } from "contexts/PriorityModalContext";
import { SessionContext } from "contexts/SessionContext";
import { get, replace } from "lodash";
import { useContext, useEffect, useState } from "react";
import Api from "services/Api";
import { replaceAll } from "utils/replaceAll";
import { formatSocialPostObj } from "../../formatSocialPostObj";
import axios from "axios";
import { getSocialNetworkIntegration } from "utils/getSocialNetworkIntegration";
import dayjs from "dayjs";

export const usePinterest = () => {

    const { company, companySocials } = useContext(CompanyContext);
    const _start_date = new Date(new Date().getTime() - 30 * 24 * 3600 * 1000);
    const _end_date = new Date();

    const createPinterestBoard = async (name) => {
        const socialNetwork = getSocialNetworkIntegration("pinterest", companySocials);
        if (!socialNetwork) return [];

        let board;
        try {
            board = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
                url: `https://api.pinterest.com/v5/boards`,
                method: "post",
                params: {
                    name: name
                }
            }).then((_board) => _board);
        } catch {}

        return board;
    }

    const getPinterestBoards = async (useCache = true) => {
        const socialNetwork = getSocialNetworkIntegration("pinterest", companySocials);
        if (!socialNetwork) return [];
        
        let boards = [];

        if (useCache && localStorage.getItem(`${socialNetwork?.id}_boards`)) {
            const cacheBoards = JSON.parse(localStorage.getItem(`${socialNetwork?.id}_boards`));
            if (new Date(cacheBoards?.expires_at) > new Date()) {
                boards = cacheBoards?.boards;
            }
        }

        if (!boards?.length) {
            try {
                boards = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy?rand=${Math.random()}`, {}, {
                    url: `https://api.pinterest.com/v5/boards`,
                    method: "get",
                    params: {
                        page_size: 250
                    }
                }).then(({ items }) => items);
                localStorage.setItem(`${socialNetwork?.id}_boards`, JSON.stringify({
                    expires_at: new Date().getTime() + 1000 * 60 * 60,
                    boards: boards
                }));
            } catch {}
        }

        boards = boards.sort((a,b)=>new Date(b.created_at).getTime()-new Date(a.created_at).getTime());

        return boards && boards?.length ? boards : [];
    }
    
    
    const getPinterestPostsInsights = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("pinterest", companySocials);
        if (!socialNetwork) return [];

        let posts = await getPinterestPosts(per_page, start_date, end_date);

        return posts;
    }


    const getPinterestPosts = async (
        per_page = 100, // 100
        start_date = _start_date,
        end_date = _end_date
    ) => {
        const socialNetwork = getSocialNetworkIntegration("pinterest", companySocials);
        if (!socialNetwork) return [];

        start_date = new Date(start_date).toISOString();
        end_date = new Date(end_date).toISOString();

        if (localStorage.getItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`)) {
            return JSON.parse(localStorage.getItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`));
        }

        return await Api.get(`/socialNetworks/${socialNetwork.id}/posts?${start_date ? `&start_date=${dayjs(start_date).format('YYYY-MM-DD')}` : ``}${end_date ? `&end_date=${dayjs(end_date).format('YYYY-MM-DD')}` : ``}&pin_metrics=true`).then(async ({ data: posts }) => { 
            
            posts = posts?.filter((post) => new Date(post?.created_at) >= new Date(start_date) && new Date(post?.created_at) <= new Date(end_date));
            
            for (var i = 0; i < posts.length; i++) {
                const response = await Api.post(`/socialNetworks/${socialNetwork.id}/proxy`, {}, {
                    url: `https://api.pinterest.com/v5/pins/${posts[i]?.id}/analytics`,
                    method: "get",
                    params: {
                        start_date: dayjs().format('YYYY-MM-DD'),
                        end_date: dayjs().format('YYYY-MM-DD'),
                        metric_types: "IMPRESSION,SAVE,TOTAL_COMMENTS,TOTAL_REACTIONS"
                    }
                }).then((response) => response);
                posts[i].like_count = response?.all?.lifetime_metrics?.['TOTAL_REACTIONS'];
                posts[i].comment_count = response?.all?.lifetime_metrics?.['TOTAL_COMMENTS']
            }

            posts = posts?.map((post) => {
                const media = post?.media?.items?.length 
                    ? post?.media?.items?.map((item) => {
                        return {
                            type: item?.item_type, 
                            url: item?.images?.[`1200x`]?.url, 
                            width: item?.images?.[`1200x`]?.width, 
                            height: item?.images?.[`1200x`]?.height
                        }
                    })
                    : [{ 
                        type: post?.media?.media_type, 
                        url: post?.media?.images?.[`1200x`]?.url, 
                        width: post?.media?.images?.[`1200x`]?.width, 
                        height: post?.media?.images?.[`1200x`]?.height
                    }];
                return formatSocialPostObj(
                    "pinterest",
                    post?.id,
                    socialNetwork?.external_id,
                    socialNetwork?.name,
                    socialNetwork?.username,
                    socialNetwork?.image?.url,
                    post?.created_at,
                    post?.description,
                    media,
                    post?.like_count, // like
                    post?.comment_count, // comment
                    post?.pin_metrics?.all_time?.impression, // view
                    post?.pin_metrics?.all_time?.save, // retweet
                    post?.title,
                    post?.link,
                    "", // embed_link
                    "FEED", // media_product_type
                    false, // user_verified
                    [], // comments
                    [], // assets
                    undefined, // preferences
                    0, // postIndex
                    0, // followers_count
                    [], // comments_to_create
                    post?.cover
                )
            });
            localStorage.setItem(`${company.id}_${socialNetwork.id}_${start_date.split("T")[0]}${end_date.split("T")[0]}`, JSON.stringify(posts));
            return posts; 
        });
    }

    return { getPinterestPosts, getPinterestBoards, createPinterestBoard, getPinterestPostsInsights }
}

