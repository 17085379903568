import React, { useContext, useEffect, useState } from 'react'
import {
    Input,
    Avatar,
    Upload,
    Button,
    Select,
    Switcher,
    Notification,
    toast,
    FormContainer,
    FormItem,
} from 'components/ui'
import FormDesription from './FormDesription'
import FormRow from './FormRow'
import { Field, Form, Formik, getIn } from 'formik'
import { components } from 'react-select'
import {
    HiOutlineUserCircle,
    HiOutlineMail,
    HiOutlineBriefcase,
    HiOutlineUser,
    HiCheck,
    HiOutlineGlobeAlt,
    HiOutlineOfficeBuilding,
    HiOutlineGlobe,
} from 'react-icons/hi'
import * as Yup from 'yup'
import { SessionContext } from 'contexts/SessionContext'
import { setUser } from 'store/auth/userSlice'
import InputModern from 'components/ui/Input/InputModern'
import Api from 'services/Api'
import Resizer from "react-image-file-resizer";
import { CompanyContext } from 'contexts/CompanyContext'
import { languageOptions } from 'views/onboarding/StepCompanyLanguage'
import { communicationStyleOptions } from 'views/onboarding/StepCompanyCommunicationStyle'
import { companyTypeOptions } from 'views/onboarding/StepCompanyType'
import CreatableSelect from 'react-select/creatable'
import { audienceAgeOptions, audienceGenderOptions } from 'views/onboarding/StepCompanyAudienceAgeGender'
import { SketchPicker } from 'react-color'
import { rgbToHex } from 'utils/rgbToHex'
import { HiOutlineCpuChip, HiOutlinePlus } from 'react-icons/hi2'
import { extractColorsFromSrc } from 'extract-colors'
import { TbWand } from 'react-icons/tb'
import { colorIsLight, extractDominantColors } from 'utils/colorUtils'
import axios from 'axios'
import { Trans } from 'react-i18next'

const { Control, MultiValueLabel } = components

const validationSchema = Yup.object().shape({
    name: Yup.string()
        .min(3, 'Too Short!')
        .max(50, 'Too Long!')
        .required('User Name Required'),
    
})

export const CustomSelectOption = ({ innerProps, label, data, isSelected, test }) => {
    return (
        <div
            className={`flex items-center justify-between p-2 ${
                isSelected
                    ? 'bg-gray-100 dark:bg-gray-500'
                    : 'hover:bg-gray-50 dark:hover:bg-gray-600'
            }`}
            {...innerProps}
        >
            <div className="flex items-center">
                {data?.image && <Avatar shape="circle" size={20} src={data.image} />}
                {data?.emoji && <span className='text-xl'>{data.emoji}</span>}
                <span className="ml-2 rtl:mr-2">{data?.label ? data?.label : data?.value}</span>
            </div>
            {isSelected && <HiCheck className="text-emerald-500 text-xl" />}
        </div>
    )
}

const CustomControlMulti = ({ children, data, ...props }) => {
    return (
        <MultiValueLabel {...props}>
            <div className="inline-flex items-center">
                {data?.image && <Avatar shape="circle" size={20} src={data.image} />}
                {data?.emoji && <span className='text-xl'>{data.emoji}</span>}
                <span className='ml-2'>
                    {data?.value}
                </span>
                {children}
            </div>
        </MultiValueLabel>
    )
}

export const CustomControl = ({ children, ...props }) => {
    const selected = props.getValue()[0]
    return (
        <Control {...props}>
            {selected && (
                <>
                    {selected?.image && <Avatar className="ltr:ml-4 rtl:mr-4" shape="circle" size={18} src={selected.image}/>}
                    {selected?.emoji && <span className='text-xl ml-4'>{selected.emoji}</span>}
                    <span className='ml-2'>
                        {selected.value}
                    </span>
                </>
            )}
            {children}
        </Control>
    )
}

export const calculateWordLength = (value) => {
    value = String(value);
    return value?.trim()?.split(' ').filter(function(n) { return n != '' })?.length
}

const CompanyProfile = () => {

    const { updateUserData } = useContext(SessionContext);
    const { company } = useContext(CompanyContext);
    const [ file, setFile ] = useState();
    const [ audienceOptions, setAudienceOptions ] = useState([]);
    const [ interestsOptions, setInterestsOptions ] = useState([]);
    const [ initialAudienceOptions, setInitialAudienceOptions ] = useState([]);
    const [ initialInterestsOptions, setInitialInterestsOptions ] = useState([]);
    const [ openedPicker, setOpenedPicker ] = useState(-1);
    const [ palette, setPalette ] = useState();
    const [ words, setWords ] = useState();
    const [ isLoadingColorPalette, setIsLoadingColorPalette ] = useState(false);
    const logoSize = 512;

    const onSetFormFile = (form, field, file) => {
        setFile(file[0]);
        form.setFieldValue(field.name, URL.createObjectURL(file[0]))
    }

    const resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(file, logoSize, logoSize, file.type.includes("png") ? "PNG" : "JPEG", 100, 0, (uri) => {
                resolve(uri);
            },
            "file"
          );
        });
    }

    const onFormSubmit = (values, setSubmitting) => {
        if (!validate(values)) {
            setSubmitting(false)
            return
        }
        Api.put(`/companies/${company?.id}`, {}, { 
            name: values?.name, 
            palettes: palette ? palette : undefined,
            website: values?.website ? values?.website : undefined,
            description: values?.description,
            type: values?.type ? values?.type?.toLowerCase() : 'company',
            language: values?.language ? values?.language : undefined,
            language_iso_code: values?.language_iso_code ? values?.language_iso_code : undefined,
            language_type: values?.language_type ? values?.language_type : undefined,
            interests: values?.interests?.map(item => item.value),
            audience_types: values?.audience_types?.map(item => item.value),
            audience_ages: values?.audience_ages?.map(item => item.value),
            audience_genders: values?.audience_genders?.map(item => item.value),
        }).then((response) => {
            if (file) {
                resizeFile(file).then((_data) => {
                    const file = _data; 
                    const type = file.type.includes("image") ? "image" : "video";
                    Api.post(`/companies/${company?.id}/image`, {}, { filename: file.name, type: type, visibility: "public" }).then((response) => {
                        const aws_uri = response.data.presigned_url;
                        axios.put(aws_uri, file, { headers: { 'Content-type': file.type } }).then(() => {
                            setSubmitting(false);
                            updateUserData();
                            toast.push(<Notification title={<Trans i18nKey={`companyProfile.companyUpdated`}>Company updated</Trans>} type="success" />, {
                                placement: 'top-center',
                            })
                        });
                    });
                });
                setFile(undefined);
            } else {
                setSubmitting(false);
                updateUserData();
                toast.push(<Notification title={<Trans i18nKey={`companyProfile.companyUpdated`}>Company updated</Trans>} type="success" />, {
                    placement: 'top-center',
                })
            }
        });
    }

    const validate = (values) => {
        if (!values?.name?.trim()) {
            toast.push(<Notification title={<Trans i18nKey={`companyProfile.companyNameValidation`}>Company name can't be empty</Trans>} type="danger" />, { placement: 'top-center' })
            return false;
        }
        // if (calculateWordLength(values?.description) < 20 || calculateWordLength(values?.description) > 1000) {
        //     toast.push(<Notification title={<Trans i18nKey={`companyProfile.companyDescriptionValidation`}>Company description must be between 20 and 1000 words long</Trans>} type="danger" />, { placement: 'top-center' })
        //     return false;
        // }
        // if (values?.interests?.length < 3) {
        //     toast.push(<Notification title={<Trans i18nKey={`companyProfile.companyInterestsValidation`}>You must set at least 3 interests</Trans>} type="danger" />, { placement: 'top-center' })
        //     return false;
        // }
        // if (values?.audience_types?.length < 3) {
        //     toast.push(<Notification title={<Trans i18nKey={`companyProfile.companyAudiencesValidation`}>You must set at least 3 audiences</Trans>} type="danger" />, { placement: 'top-center' })
        //     return false;
        // }

        return true;
    }

    const extractColors = () => {
        extractDominantColors(file ? URL.createObjectURL(file) : company?.image?.url).then((colors) => {
            setPalette(colors);
        }).catch(console.error);
    }

    const getRandomPalette = async () => {
        var url = "http://colormind.io/api/";
        var data = { model : "default" }
        var http = new XMLHttpRequest();
        http.onreadystatechange = function() {
            if(http.readyState == 4 && http.status == 200) {
                setIsLoadingColorPalette(false);
                var palettes = JSON.parse(http.responseText).result;
                palettes = palettes.map(item => rgbToHex(...item));
                if (palettes?.length > 3) {
                    palettes.length = 3;
                }
                setPalette(palettes);
            }
        }
        http.open("POST", url, true);
        http.send(JSON.stringify(data));
    }

    useEffect(() => {
        document.body.addEventListener('click', (e) => {
            setOpenedPicker(-1)
        }, false)
    }, []);

    useEffect(() => {
        setAudienceOptions(company?.audience_types?.map((item) => { return { value: item, label: item } }));
        setInterestsOptions(company?.interests?.map((item) => { return { value: item, label: item } }));
        setPalette(company?.palettes);
        setWords(calculateWordLength(company?.description));
        
        setInitialInterestsOptions(company?.interests?.map((item) => { return { value: item, label: item } }));
        setInitialAudienceOptions(company?.audience_types?.map((item) => { return { value: item, label: item } }));
    }, [company]);
    
    useEffect(() => {
        if (openedPicker >= 0) {
            var elements = document.getElementsByClassName("sketch-picker");
            Array.from(elements).forEach(function(element) {
                element.addEventListener('click', (e) => {
                    e.stopPropagation();
                    e.stopImmediatePropagation();
                });
            });
        }
    }, [openedPicker])

    return (
        <Formik
            initialValues={{
                avatar: company?.image?.url,
                ...company,
                interests: initialInterestsOptions,
                audience_types: initialAudienceOptions,
                audience_ages: audienceAgeOptions.filter(option => company?.audience_ages?.includes(option.value)),
                audience_genders: audienceGenderOptions.filter(option => company?.audience_genders?.includes(option.value))
            }}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                setSubmitting(true)
                onFormSubmit(values, setSubmitting)
            }}
        >
            {({ values, touched, errors, isSubmitting, setSubmitting, resetForm }) => {
                const validatorProps = { touched, errors }
                return (
                    <Form>
                        <FormContainer>
                            <FormDesription
                                title={<Trans i18nKey={`companyProfile.general`}>General</Trans>}
                                desc={<Trans i18nKey={`companyProfile.generalDesc`}>Company logo, name, interests, description and more</Trans>}
                            />

                            <FormRow
                                name="avatar"
                                label={<Trans i18nKey={`companyProfile.logo`}>Logo</Trans>}
                                {...validatorProps}
                            >
                                <Field name="avatar">
                                    {({ field, form }) => {
                                        const avatarProps = field.value
                                            ? { src: field.value }
                                            : {}
                                        return (
                                            <Upload
                                                className="cursor-pointer"
                                                onChange={(files) =>
                                                    onSetFormFile(
                                                        form,
                                                        field,
                                                        files
                                                    )
                                                }
                                                onFileRemove={(files) =>
                                                    onSetFormFile(
                                                        form,
                                                        field,
                                                        files
                                                    )
                                                }
                                                accept="image/*"
                                                showList={false}
                                                uploadLimit={1}
                                            >
                                                <Avatar
                                                    avatarClassName="!object-contain"
                                                    className={`${(company?.image?.url || file) ? `bg-transparent` : ``} border-2 border-white dark:border-gray-800`}
                                                    size={100}
                                                    shape="rounded"
                                                    icon={<HiOutlinePlus />}
                                                    {...avatarProps}
                                                />
                                            </Upload>
                                        )
                                    }}
                                </Field>
                            </FormRow>

                            <FormRow
                                name="name"
                                label={<Trans i18nKey={`companyProfile.name`}>Name</Trans>}
                                {...validatorProps}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="name"
                                    placeholder="Name"
                                    component={InputModern}
                                    className="!pt-4"
                                    prefix={
                                        <HiOutlineOfficeBuilding className="text-xl" />
                                    }
                                />
                            </FormRow>
                            
                            <FormRow
                                name="website"
                                label={<Trans i18nKey={`companyProfile.website`}>Website</Trans>}
                                {...validatorProps}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="website"
                                    placeholder="Website"
                                    component={InputModern}
                                    className="!pt-4"
                                    prefix={
                                        <HiOutlineGlobe className="text-xl" />
                                    }
                                />
                            </FormRow>

                            <FormRow
                                name="description"
                                label={<Trans i18nKey={`companyProfile.description`}>Description</Trans>}
                                {...validatorProps}
                            >
                                <Field
                                    type="text"
                                    autoComplete="off"
                                    name="description"
                                    placeholder="Description"
                                    component={InputModern}
                                    textArea={true}
                                    className="!pt-4"
                                    onKeyUp={(e) => setWords(calculateWordLength(e.target.value))}
                                />
                                <small>Word count: {words} / 1,000</small>
                            </FormRow>

                            <FormRow
                                name="type"
                                label={<Trans i18nKey={`companyProfile.type`}>Type</Trans>}
                                {...validatorProps}
                            >
                                <Field name="type">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={companyTypeOptions}
                                            components={{
                                                Option: CustomSelectOption,
                                                Control: CustomControl,
                                            }}
                                            value={companyTypeOptions.filter(
                                                (option) =>
                                                    option.value?.toLowerCase() ===
                                                    values?.type?.toLowerCase()
                                            )}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.value
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormRow>

                            <FormRow
                                name="language"
                                label={<Trans i18nKey={`companyProfile.language`}>Language</Trans>}
                                {...validatorProps}
                            >
                                <Field name="language">
                                    {({ field, form }) => (
                                        <Select
                                            field={field}
                                            form={form}
                                            options={languageOptions}
                                            components={{
                                                Option: CustomSelectOption,
                                                Control: CustomControl,
                                            }}
                                            value={languageOptions.filter(
                                                (option) =>
                                                    option.value ===
                                                    values?.language
                                            )}
                                            onChange={(option) => {
                                                form.setFieldValue(field.name, option.value)
                                                form.setFieldValue('language_iso_code', option.iso_code)
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormRow>
                            
                            <FormRow
                                name="audience_ages"
                                label={<Trans i18nKey={`companyProfile.audiencesAges`}>Audiences Ages</Trans>}
                                {...validatorProps}
                            >
                                <Field name="audience_ages">
                                    {({ field, form }) => (
                                        <Select
                                            isMulti
                                            field={field}
                                            form={form}
                                            options={audienceAgeOptions}
                                            components={{
                                                Option: CustomSelectOption,
                                                MultiValueLabel: CustomControlMulti,
                                            }}
                                            // value={audienceAgeOptions.filter(
                                            //     (option) =>
                                            //         option.value ===
                                            //         values?.language_type
                                            // )}
                                            onChange={(option) => {
                                                form.setFieldValue(
                                                    field.name,
                                                    option.map(item => item)
                                                )
                                            }}
                                        />
                                    )}
                                </Field>
                            </FormRow>
                            
                            <FormRow
                                name="audience_genders"
                                label={<Trans i18nKey={`companyProfile.audiencesGenders`}>Audiences Genders</Trans>}
                                {...validatorProps}
                            >
                                <Field name="audience_genders">
                                    {({ field, form }) => (
                                        <Select
                                            isMulti
                                            field={field}
                                            form={form}
                                            options={audienceGenderOptions}
                                            components={{
                                                Option: CustomSelectOption,
                                                MultiValueLabel: CustomControlMulti,
                                            }}
                                            onChange={(option) =>
                                                form.setFieldValue(
                                                    field.name,
                                                    option.map(item => item)
                                                )
                                            }
                                        />
                                    )}
                                </Field>
                            </FormRow>
                            
                            <div className="mt-4 ltr:text-right">
                                <Button
                                    variant="solid"
                                    loading={isSubmitting}
                                    type="submit"
                                >
                                    {isSubmitting ? 
                                        <Trans i18nKey={`companyProfile.updating`}>Updating</Trans> : 
                                        <Trans i18nKey={`companyProfile.update`}>Update</Trans>
                                    }
                                </Button>
                            </div>
                        </FormContainer>
                    </Form>
                )
            }}
        </Formik>
    )
}

export default CompanyProfile
