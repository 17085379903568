import React from 'react';
import classNames from 'classnames';

const BasicRow = ({ label, description, children, border = true, alignCenter = true }) => {
  return (
    <div
      className={classNames(
        'grid md:grid-cols-4 gap-4 py-8',
        border && 'border-b border-gray-200 dark:border-gray-600',
        alignCenter && 'items-center'
      )}
    >
      <div>
        <div className="font-semibold text-gray-900 dark:text-gray-100 text-base">
          {label}
        </div>
        {description && (
          <div className="text-xs text-gray-600 dark:text-gray-400">
            {description}
          </div>
        )}
      </div>
      <div className="col-span-3">
        <div className="mb-0 max-w-[700px]">
          {children}
        </div>
      </div>
    </div>
  );
};

export default BasicRow; 