import { useState, useEffect, useContext } from 'react';
import Api from 'services/Api';
import { CompanyContext } from 'contexts/CompanyContext';

const usePrompts = () => {
    const { company } = useContext(CompanyContext);
    const [followrPrompts, setFollowrPrompts] = useState([]);
    const [companyPrompts, setCompanyPrompts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const fetchFollowrPrompts = async () => {
        setLoading(true);
        setError(null);
        let allPrompts = [];
        
        try {
            // First call to get initial data and pagination info
            const initialResponse = await Api.get(`/prompts?include=user,user.image.thumbnail&sort=-created_at&filter[company_id]=null&page[size]=30`);
            allPrompts = [...initialResponse.data];
            
            const totalPages = Math.ceil(initialResponse.meta.total / initialResponse.meta.per_page);
            
            // Make subsequent calls for remaining pages
            if (totalPages > 1) {
                const remainingCalls = [];
                for (let page = 2; page <= totalPages; page++) {
                    remainingCalls.push(
                        Api.get(`/prompts?include=user,user.image.thumbnail&sort=-created_at&filter[company_id]=null&page[number]=${page}&page[size]=30`)
                    );
                }
                
                const responses = await Promise.all(remainingCalls);
                responses.forEach(response => {
                    allPrompts = [...allPrompts, ...response.data];
                });
            }
            
            setFollowrPrompts(allPrompts);
        } catch (error) {
            console.error('Error fetching followr prompts:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const fetchCompanyPrompts = async () => {
        setLoading(true);
        setError(null);
        let allPrompts = [];
        
        try {
            // First call to get initial data and pagination info
            const initialResponse = await Api.get(`/prompts?include=user,user.image.thumbnail&sort=-created_at&filter[company_id]=${company.id}&page[size]=30`);
            allPrompts = [...initialResponse.data];
            
            const totalPages = Math.ceil(initialResponse.meta.total / initialResponse.meta.per_page);
            
            // Make subsequent calls for remaining pages
            if (totalPages > 1) {
                const remainingCalls = [];
                for (let page = 2; page <= totalPages; page++) {
                    remainingCalls.push(
                        Api.get(`/prompts?include=user,user.image.thumbnail&sort=-created_at&filter[company_id]=${company.id}&page[number]=${page}&page[size]=30`)
                    );
                }
                
                const responses = await Promise.all(remainingCalls);
                responses.forEach(response => {
                    allPrompts = [...allPrompts, ...response.data];
                });
            }
            
            setCompanyPrompts(allPrompts);
        } catch (error) {
            console.error('Error fetching company prompts:', error);
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    const createPrompt = async (network, name = null, prompt = null) => {
        try {
            const response = await Api.post('/prompts', {}, {
                name: name || `Prompt Title`,
                prompt: prompt || `Write a...`,
                company_id: company?.id,
                type: 'text',
                social_network_type: network,
                default: false
            });

            const newPrompt = {
                ...response.data,
                isNew: !name && !prompt
            };

            setCompanyPrompts(prev => [...prev, newPrompt]);
            return newPrompt;
        } catch (error) {
            console.error('Error creating prompt:', error);
            throw error;
        }
    };

    const deletePrompt = async (prompt) => {
        try {
            await Api.delete(`/prompts/${prompt.id}`);

            await fetchCompanyPrompts();
        } catch (error) {
            console.error('Error deleting prompt:', error);
            throw error;
        }
    };

    const updatePrompt = async (promptId, promptData) => {
        try {
            await Api.put(`/prompts/${promptId}`, {}, promptData);

            await fetchCompanyPrompts();
        } catch (error) {
            console.error('Error updating prompt:', error);
            throw error;
        }
    };

    const getPromptForNetwork = (network, query = "") => {
        let configs = "";
        let querySuffix = query 
            ? `You must write about ${query}. ` 
            : company && company?.interests?.length
                ? `You must write about ${company?.interests[Math.floor(Math.random() * company?.interests?.length)]}. `
                : ``;
        // socialMediaPrompts[network]['configs'].map((config) => {
        //     if (randomBool()) {
        //         configs += config;
        //     }
        // });
        let finalPrompt = prompt + querySuffix + configs;
        return finalPrompt
    }

    const getDefaultPromptsForNetwork = async (network) => {
        await fetchFollowrPrompts();
        await fetchCompanyPrompts();
        
        const networkPrompts = [
            ...(followrPrompts || []),
            ...(companyPrompts || [])
        ];
        
        return networkPrompts.filter(p => p.default && p.social_network_type === network);
    };

    const getRandomPromptForNetwork = (network) => {
        const defaultPrompts = [...followrPrompts, ...companyPrompts]
            .filter(p => p.default && p.social_network_type === network);
        
        if (defaultPrompts.length > 0) {
            return defaultPrompts[Math.floor(Math.random() * defaultPrompts.length)];
        }

        const allPrompts = [...followrPrompts, ...companyPrompts]
            .filter(p => p.social_network_type === network);
            
        return allPrompts.length > 0 
            ? allPrompts[Math.floor(Math.random() * allPrompts.length)]
            : null;
    };

    return {
        followrPrompts,
        companyPrompts,
        prompts: [...followrPrompts, ...companyPrompts],
        loading,
        error,
        fetchFollowrPrompts,
        fetchCompanyPrompts,
        createPrompt,
        deletePrompt,
        updatePrompt,
        getDefaultPromptsForNetwork,
        getRandomPromptForNetwork,
    };
};

export default usePrompts;